import { AuthLayout } from '../../components/AuthLayout';
import React from 'react';

export const RegisterSuccess: React.FC = () => {
  return (
    <AuthLayout>
      <div>
        Registration successful! Please confirm your email by clicking the
        button in the email we sent you.
      </div>
    </AuthLayout>
  );
}
