import {
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as r from 'runtypes';
import { KaiAdsSSPAPI } from '../../api';
import { ctr } from '../../utils';
import _ from 'lodash';

const RawReport = r.Record({
  Time: r.String,
  AppName: r.String,
  Impressions: r.Number,
  Clicks: r.Number,
  EstimatedRevenue: r.Number,
  ECPM: r.Number,
  Finalized: r.Boolean,
});
export type RawReport = r.Static<typeof RawReport>;

export type ExportableReport = {
  Time: string;
  AppName: string;
  Impressions: string;
  Clicks: string;
  Ctr: string;
  EstimatedRevenue: string;
  ECPM: string;
};

export type DisplayedReport = ExportableReport & { Finalized: boolean, EstimatedRevenueRaw: number };

export type DetailsState = {
  ter: string;
  apps: string[];
  selectedApps: string[];
  formattedData: DisplayedReport[] | undefined;
  displayData: DisplayedReport[] | undefined; // undefined means no data has been loaded yet
  loading: boolean;
  error: string;
};

const initialState: DetailsState = {
  ter: '0',
  formattedData: undefined,
  displayData: undefined,
  loading: false,
  error: '',
  apps: [],
  selectedApps: [],
};

export const submitAsync = createAsyncThunk(
  'details/submit',
  async (d: FormData) => {
    const res = await KaiAdsSSPAPI.stat(d);
    const data = r.Array(RawReport).check(res.data) as RawReport[];

    return data;
  }
);

function updateFilter(state: Draft<DetailsState>) {
  if (!state.formattedData) {
    return;
  }
  const displayedData: DisplayedReport[] =
    state.selectedApps.length > 0
      ? state.formattedData.filter((x) =>
          state.selectedApps.some((f) => f === x.AppName)
        )
      : state.formattedData;
  state.displayData = displayedData;
  state.ter = ter(displayedData);
}

export const slice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    filter: (state, action: PayloadAction<string[]>) => {
      state.selectedApps = action.payload;
      updateFilter(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitAsync.pending, (state) => {
      state.loading = true;
      state.ter = '0';
    });
    builder.addCase(submitAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.error = '';
      state.selectedApps = [];

      state.formattedData = action.payload.map((x) => ({
        Time: x.Time,
        AppName: x.AppName,
        Impressions: '' + x.Impressions,
        Clicks: '' + x.Clicks,
        Ctr: ctr(x.Clicks, x.Impressions) + '%',
        EstimatedRevenue: x.EstimatedRevenue.toFixed(2),
        EstimatedRevenueRaw: x.EstimatedRevenue,
        ECPM: x.ECPM.toFixed(2),
        Finalized: x.Finalized,
      }));
      state.apps = _.uniq(state.formattedData.map((x) => x.AppName));
      updateFilter(state);
    });
    builder.addCase(submitAsync.rejected, (state) => {
      state.error = 'Network error, please try again';
      state.loading = false;
    });
  },
});

// based on https://github.com/taijinlee/humanize/
function humanizeNumber(
  number: number,
  kilo?: number,
  units?: string[],
  decimals?: number,
  decPoint?: string,
  thousandsSep?: string,
  suffixSep?: string
) {
  if (isNaN(number) || typeof number === undefined) {
    return '';
  }
  let humanized, unit;
  units = units || ['', 'K', 'M', 'B'];
  unit = units.length - 1;
  kilo = kilo || 1000;
  decimals = decimals || NaN;
  decimals = isNaN(decimals) ? 2 : Math.abs(decimals);
  decPoint = decPoint || '.';
  thousandsSep = thousandsSep || ',';
  suffixSep = suffixSep || '';

  for (let i = 0; i < units.length; i++) {
    if (number < Math.pow(kilo, i + 1)) {
      unit = i;
      break;
    }
  }
  humanized = number / Math.pow(kilo, unit);

  let suffix = units[unit] ? suffixSep + units[unit] : '';
  return humanized.toFixed(decimals) + suffix;
}
let ter = (y: DisplayedReport[]) =>
  humanizeNumber(y.map((x) => x.EstimatedRevenueRaw).reduce((a, b) => a + +b, 0));
