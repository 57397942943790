import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from '../../components/MainLayout';
import { loadAsync, saveAsync, slice } from './changeDomainSlice';

export const ChangeDomain: React.FC = () => {
  return (
    <MainLayout>
      <div className='container'>
        <h1 className='title h1' data-cy='title'>
          Change account info
        </h1>
        <div className='columns is-centered'>
          <div className='column is-5'>
            <DomainUpdateForm />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

function DomainUpdateForm() {
  const state = useSelector((state: any) => state[slice.name]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAsync());
  }, [dispatch]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(saveAsync(new FormData(e.currentTarget)));
      }}
    >
      <div className='field'>
        <label className='label'>Domain</label>
        <div className='control'>
          <input
            className='input'
            type='text'
            name='domain'
            pattern='^(?:[_a-z0-9](?:[_a-z0-9-]{0,61}[a-z0-9])?\.)+(?:[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?)?$'
            placeholder='e.g. your-domain.com'
            defaultValue={state.domain}
            required
            data-cy='domain'
          />
        </div>
        {
          state.failed &&
          <p className='help is-danger' data-cy='formErr'>{state.failMsg}</p>
        }
      </div>
      <p className='help is-success'>{state.msg}</p>
      <div className='field'>
        <p className='control'>
          <button
            type='submit'
            className='button is-block is-primary'
            disabled={state.submitting}
            data-cy='saveBtn'
          >
            Save
          </button>
        </p>
      </div>
    </form>
  );
}
