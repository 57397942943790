import http from './http';
import { DateTime } from 'luxon';

type dayOrMonth = ('day'|'month')

function summary_since(from: DateTime, by: string, pubID: string) {
  let d = new FormData();
  d.set('from', '' + from.toSeconds());
  d.set('tzoffset', '+0');
  d.set('by', by);
  d.set('order', 'asc');
  return http.post(`/stats/${pubID}`, d);
}

function analytics(d: FormData) {
  let zone = { zone: 'utc' };
  d.set('tzoffset', '+0');
  let fmt = d.get('by') === 'day' ? 'dd/MM/yyyy' : 'MM/yyyy';
  d.set('from', '' + DateTime.fromFormat('' + d.get('from'), fmt, zone).toSeconds());
  d.set('to', '' + DateTime.fromFormat('' + d.get('to'), fmt,zone).endOf(d.get('by') as dayOrMonth).startOf('day').toSeconds());
  let publisher_id = d.get('publisher_id');
  d.delete('publisher_id');
  return http.post(`/stats/${publisher_id}/analytics2`, d);
}

function stat(d: FormData) {
  let zone = { zone: 'utc' };
  d.set('tzoffset', '+0');
  let fmt = d.get('by') === 'day' ? 'dd/MM/yyyy' : 'MM/yyyy';
  d.set('from', '' + DateTime.fromFormat('' + d.get('from'), fmt, zone).toSeconds());
  d.set('to', '' + DateTime.fromFormat('' + d.get('to'), fmt,zone).endOf(d.get('by') as dayOrMonth).startOf('day').toSeconds());
  let publisher_id = d.get('publisher_id');
  d.delete('publisher_id');
  return http.post(`/stats/${publisher_id}`, d);
}

function installs(d: FormData) {
  let zone = { zone: 'utc' };
  d.set('tzoffset', '+0');
  let fmt = d.get('by') === 'day' ? 'dd/MM/yyyy' : 'MM/yyyy';
  d.set('from', '' + DateTime.fromFormat('' + d.get('from'), fmt, zone).toSeconds());
  d.set('to', '' + DateTime.fromFormat('' + d.get('to'), fmt,zone).endOf(d.get('by') as dayOrMonth).startOf('day').toSeconds());
  let publisher_id = d.get('publisher_id');
  d.delete('publisher_id');
  return http.post(`/stats/${publisher_id}/installs`, d);
}

function installs_raw(d: FormData) {
  let zone = { zone: 'utc' };
  d.set('tzoffset', '+0');
  let fmt = d.get('by') === 'day' ? 'dd/MM/yyyy' : 'MM/yyyy';
  d.set('from', '' + DateTime.fromFormat('' + d.get('from'), fmt, zone).toSeconds());
  d.set('to', '' + DateTime.fromFormat('' + d.get('to'), fmt,zone).endOf(d.get('by') as dayOrMonth).startOf('day').toSeconds());
  let publisher_id = d.get('publisher_id');
  d.delete('publisher_id');
  return http.post(`/stats/${publisher_id}/installs_raw`, d);
}

function publishers() {
  return http.get(`/user/`);
}

export { stat, summary_since, publishers, analytics, installs, installs_raw };
