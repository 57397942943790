import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { KaiAdsSSPAPI } from '../../api';

const initialState = {
  msg: '',
  error: '',
};

export type VerifyState = typeof initialState;

export const verifyAsync = createAsyncThunk(
  'verify/verify',
  async (urlParams: URLSearchParams, { dispatch }) => {
    return KaiAdsSSPAPI.verify(urlParams.get('uid') || '', urlParams.get('key') || '')
      .then((res) => dispatch(slice.actions.msg(res.data.msg)))
      .catch((res) =>
        dispatch(
          slice.actions.error(
            res.data ? res.data.msg : 'Unknown error occurred'
          )
        )
      );
  }
);

export const slice = createSlice({
  name: 'verify',
  initialState,
  reducers: {
    msg: (state, action) => {
      state.msg = action.payload;
    },
    error: (state, action) => {
      state.error = action.payload;
    },
  },
});
