import React from 'react';
import { Link } from 'react-router-dom';
import { AuthLayout } from '../../components/AuthLayout';
import { saveAsync, slice } from './loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const RegisterLink = styled(Link)`
  color: #6722aa !important;
`;

export const Login: React.FC = () => {
  const state = useSelector((state: any) => state[slice.name]);
  const dispatch = useDispatch();

  return (
    <AuthLayout>
      <h1 className='title is-size-3-mobile is-size-2' data-cy='formTitle'>
        Log In
      </h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(saveAsync({ form: new FormData(e.currentTarget) }));
        }}
      >
        <div className='field'>
          <div className='control has-icons-left'>
            <input
              onChange={() => dispatch(slice.actions.msg(''))}
              name='email'
              className='input'
              type='email'
              placeholder='Your Email'
              autoFocus={true}
              required
              data-cy='formEmail'
            />
            <span className='icon is-left has-text-primary'>
              <i className='fas fa-user'></i>
            </span>
          </div>
        </div>

        <div className='field'>
          <div className='control has-icons-left'>
            <input
              onChange={() => dispatch(slice.actions.msg(''))}
              name='password'
              className='input'
              type='password'
              placeholder='Your Password'
              required
              data-cy='formPassword'
            />
            <span className='icon is-left has-text-primary'>
              <i className='fas fa-lock'></i>
            </span>
          </div>
        </div>
        <div className='field'>
          <div className='checkbox'>
            <input
              id='remember-me'
              name='remember'
              type='checkbox'
              data-cy='agreeCheck'
            />
            <label className='label' htmlFor='remember-me' data-cy='rmbMe'>
              Remember me
            </label>
          </div>
        </div>
        <p id='error' className='help is-danger' data-cy='formErr'>
          {state.msg}
        </p>
        <button
          type='submit'
          className='button is-block is-primary is-fullwidth'
          data-cy='loginBtn'
        >
          Login
        </button>
      </form>
      <p>
        <br />
        <span data-cy='belowFormText'>Don't have an account?</span>
        <RegisterLink to='/register' data-cy='signUpLink'>
          {' Sign Up.'}
        </RegisterLink>{' '}
        &nbsp;
        <br />
        <span data-cy='belowFormText'>Forgot password?</span>
        <RegisterLink to='/reset_password'>
          {' Reset Password.'}
        </RegisterLink>{' '}
      </p>
    </AuthLayout>
  );
};
