import React, { FunctionComponent } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

interface P extends ReactDatePickerProps {
  by: string;
  onChange: (date: Date) => any;
}
const DateSelect: FunctionComponent<P> = (props) => {
  return (
    <DatePicker
      {...props}
      dateFormat={props.by === 'day' ? 'dd/MM/yyyy' : 'MM/yyyy'}
      className='input'
      onChange={(d, _) => {
        props.onChange(d as Date);
      }}
      showMonthYearPicker={props.by === 'month'}
      showTwoColumnMonthYearPicker
    />
  );
};
export default DateSelect;
