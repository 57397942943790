import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KaiAdsSSPAPI } from '../../api';
import * as r from 'runtypes'
import { request_payment } from '../../api/statement';
import { push } from 'connected-react-router';

export type Settlement = {
  date: Date,
  amount: number
}
export type Balance = {
  date: Date,
  paid: number,
  outstanding: number,
  inprogress: number,
  pubID: string,
}
export type LastPaymentReqInfo = {
  publisher_id: string,
  contact_email: string,
  requested_amount: string,
  payment_method: string,
  full_or_company_name: string,
  bank_name: string,
  bank_address: string,
  bank_acc_name: string,
  swift_code: string,
  payoneer_acc: string,
  paypal_acc: string,
  kaidsp_acc: string,
  created_at: string,
}
export type StatementState = {
  dates: Date[],
  selectedDate: Date | null,
  settlements: Settlement[],
  balances: Balance[],
  lastPaymentReqInfo: LastPaymentReqInfo[],
}

const initialState: StatementState = {
  dates: [],
  selectedDate: null,
  settlements: [],
  balances: [],
  lastPaymentReqInfo: [],
};

export const getPaymentAsync = createAsyncThunk(
  'statement/get_payment',
  async (
    form: FormData
  ) => {
    let res = await KaiAdsSSPAPI.get_payment(form);
    return getPaymentResponse.check(res.data) as getPaymentResponse;
  }
);
const balanceResponse = r.Record({
  update_date: r.String,
  paid_amount: r.String,
  outstanding_amount: r.String,
  in_progress_amount: r.String,
  publisher_id: r.String,
});
export type balanceResponse = r.Static<typeof balanceResponse>
const transactionResponse = r.Record({
  transaction_date_payment: r.String,
  transaction_amount_payment: r.String,
  transaction_currency: r.String,
  payment_transaction_id: r.String,
});
export type transactionResponse = r.Static<typeof transactionResponse>
const lastPaymentReqInfoResponse = r.Record({
  publisher_id: r.String,
  contact_email: r.String,
  requested_amount: r.String,
  payment_method: r.String,
  full_or_company_name: r.String,
  bank_name: r.String,
  bank_address: r.String,
  bank_acc_name: r.String,
  swift_code: r.String,
  payoneer_acc: r.String,
  paypal_acc: r.String,
  kaidsp_acc: r.String,
  created_at: r.String,
});
export type lastPaymentReqInfoResponse = r.Static<typeof lastPaymentReqInfoResponse>
const getPaymentResponse = r.Tuple(
  r.Union(r.Array(balanceResponse), r.Null),
  r.Union(r.Array(transactionResponse), r.Null),
  r.Union(r.Array(lastPaymentReqInfoResponse), r.Null)
);
export type getPaymentResponse = r.Static<typeof getPaymentResponse>;

export const slice = createSlice({
  name: 'statement',
  initialState,
  reducers: {
    select_date: (state, action: PayloadAction<string | null>) => {
      state.selectedDate = action.payload ? new Date(action.payload) : null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPaymentAsync.fulfilled,
      (state, action: PayloadAction<getPaymentResponse>) => {
        const data = action.payload;

        // if (data[0] == null && data[1] == null) {
        //   // state.error = 'No data is available, please check again later.';
        // }
        state.balances = (data[0] || []).map(x => ({
          date: new Date(x.update_date),
          paid: +x.paid_amount,
          outstanding: +x.outstanding_amount,
          inprogress: +x.in_progress_amount,
          pubID: x.publisher_id,
        }));
        state.dates = state.balances.map(x => x.date);
        state.selectedDate = state.dates.length > 0?state.dates[0]:null;
        state.settlements = (data[1] || []).map(x => ({
          date: new Date(x.transaction_date_payment),
          amount: +x.transaction_amount_payment
        }));
        state.lastPaymentReqInfo = (data[2] || []).map(x => ({
          publisher_id: x.publisher_id,
          contact_email: x.contact_email,
          requested_amount: x.requested_amount,
          payment_method: x.payment_method,
          full_or_company_name: x.full_or_company_name,
          bank_name: x.bank_name,
          bank_address: x.bank_address,
          bank_acc_name: x.bank_acc_name,
          swift_code: x.swift_code,
          payoneer_acc: x.payoneer_acc,
          paypal_acc: x.paypal_acc,
          kaidsp_acc: x.kaidsp_acc,
          created_at: x.created_at,
        }));
      }
    );
  },
});

export const requestPaymentAsync = createAsyncThunk(
  'statement/request_payment',
  async (form: FormData, {dispatch}) => {
    await request_payment(form);
    const msg1 = encodeURIComponent("Your request has been received and will be treated by our team. (Payments are processed on 2nd and 4th Friday each month.)"); 
    const msg2 = encodeURIComponent("If you have any issue, please contact ");
    const msg3 = encodeURIComponent("developersupport@kaiostech.com");
    dispatch(push(`/payment?msg1=${msg1}&msg2=${msg2}&msg3=${msg3}`));
  }
)