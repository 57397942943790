import React from 'react';
import { sendEmailAsync, slice } from './resetPwdSlice';
import { useDispatch, useSelector } from 'react-redux';

export const EnterEmail: React.FC = () => {
  const emailMsg = useSelector((state: any) => state[slice.name].emailMsg);
  const dispatch = useDispatch();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(sendEmailAsync({ form: new FormData(e.currentTarget) }));
      }}
    >
      <div className='field'>
        <div className='control has-icons-left'>
          <input
            name='email'
            className='input'
            type='email'
            placeholder='Your Email'
            autoFocus={true}
            required
          />
          <span className='icon is-left has-text-primary'>
            <i className='fas fa-envelope'></i>
          </span>
        </div>
      </div>
      <p className='message is-danger'>{emailMsg}</p>
      <button
        type='submit'
        className='button is-block is-primary is-fullwidth'
      >
        Send Email
      </button>
    </form>
  )
}
