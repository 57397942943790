import React from 'react';

type CustomSuspenseProps = {
  loading: boolean;
  error: string;
};

export const CustomSuspense: React.FC<CustomSuspenseProps> = ({
  children,
  loading,
  error,
}) => {
  if (loading) {
    return <div className='is-primary loader' />;
  }

  if (error) {
    return <div className='help is-danger'>{error}</div>;
  }

  return <>{children}</>;
};
