import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get_domain,change_domain } from '../../auth';
import * as r from 'runtypes';

export const initialState = {
  submitting: false,
  domain: '',
  msg: '',
  failed: false,
  failMsg: '',
};

export const loadAsync = createAsyncThunk(
  'changeDomain/load',
  async (_, { rejectWithValue }) => {
    try {
      const res = await get_domain();
      return res.data;
    } catch (res) {
      if (res.data && res.data.msg) {
        return rejectWithValue(r.String.check(res.data.msg));
      }
      return '';
    }
  }
);

export const saveAsync = createAsyncThunk(
  'changeDomain/save',
  async (d: FormData, { rejectWithValue }) => {
    try {
      const res = await change_domain(d);
      return res.data;
    } catch (res) {
      if (res.data && res.data.msg) {
        return rejectWithValue(r.String.check(res.data.msg));
      }
      return '';
    }
  }
);

export const slice = createSlice({
  name: 'changeDomain',
  initialState,
  reducers: {
    failMsg: (state, action: PayloadAction<string>) => {
      state.failed = true;
      state.failMsg = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAsync.pending, (state) => {
      state.failed = false;
      state.msg = '';
      state.submitting = true;
    });
    builder.addCase(saveAsync.rejected, (state, action: PayloadAction<any>) => {
      state.failed = true;
      state.failMsg = action.payload;
      state.submitting = false;
    });
    builder.addCase(saveAsync.fulfilled, (state) => {
      state.msg ='Domain updated';
      state.submitting = false;
    });
    builder.addCase(loadAsync.fulfilled, (state, { payload }) => {
      state.domain = payload.Domain;
    });
  },
});
