import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get_otp, verify_otp, reset_password } from '../../auth';
import { push } from 'connected-react-router';
import * as r from 'runtypes';

const ResponseData = r.Record({
  msg: r.String,
  success: r.Boolean,
});

type ResponseData = r.Static<typeof ResponseData>;

export const PIN_LENGTH = 6;

/* It takes 3 steps to reset password
 * 1. Enter email to get an OTP
 * 2. Verify OTP
 * 3. Set a new password
 * So state is roughly separated into these 3 parts
 */
export const initialState = {
  userId: '',
  userEmail: '',
  emailMsg: '',

  pincode: Array.from({ length: PIN_LENGTH }, () => ''),
  pincodeMsg: '',
  pinVerified: false,
  pinResendable: false,

  passwordFailMsg: '',
  passwordSubmitting: false,
};

export const sendEmailAsync = createAsyncThunk(
  'resetPassword/send',
  async ({ form }: { form: FormData }, { dispatch, rejectWithValue }) => {
    const userEmail= form.get('email');
    dispatch(slice.actions.userEmail(userEmail))
    try {
      const results = await get_otp(form);
      return ResponseData.check(results.data) as ResponseData;
    } catch (res) {
      return rejectWithValue(r.String.check(res.data.msg))
    }
  }
);

export const verifyOTPAsync = createAsyncThunk(
  'resetPassword/verify',
  async ({ form }: { form: FormData }, { rejectWithValue }) => {
    try {
      const results = await verify_otp(form);
      return ResponseData.check(results.data) as ResponseData;
    } catch (res) {
      return rejectWithValue(r.String.check(res.data.msg as string));
    }
  }
);

export const createPasswordAsync = createAsyncThunk(
  'resetPassword/reset',
  async ({ form }: {form: FormData}, { dispatch, rejectWithValue}) => {
    try {
      await reset_password(form);
      dispatch(slice.actions.reset());
      dispatch(push('/login'));
    } catch (res) {
      return rejectWithValue(r.String.check(res.data.msg));
    }
  }
);

export const slice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    reset: state => initialState,
    pincode: (state, action: PayloadAction<any>) => {
      const { index, value, msg } = action.payload;
      state.pincode[index] = value;
      state.pincodeMsg = msg
    },
    userEmail: (state, action: PayloadAction<any>) => {
      const value = action.payload;
      state.userEmail = value;
    },
    pincodeMsg: (state, action: PayloadAction<string>) => {
      state.pincodeMsg = action.payload;
    },
    pinResendable: (state, action: PayloadAction<boolean>) => {
      state.pinResendable = action.payload;
    },
    pinVerified: (state, action: PayloadAction<boolean>) => {
      state.pinVerified = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(sendEmailAsync.pending, (state) => {
      state.pincodeMsg = '';
      state.emailMsg = 'Sending one-time password';
    });
    builder.addCase(sendEmailAsync.rejected, (state) => {
      state.emailMsg = 'Failed to send one-time password.';
      state.userId = '';
    });
    builder.addCase(sendEmailAsync.fulfilled, (state, action: PayloadAction<any>) => {
      /* success payload
       * {
       *  'msg': 'User_id:xxxxx',
       *  'success': true
       * }
       */
      const { payload } = action;
      if (payload) {
        const { msg } = action.payload;
        const userId = msg.split(':')[1];

        if (userId && userId.length > 0) {
          state.emailMsg = 'Email sent successfully. Please verify one-time password.'
          state.userId = userId;
        } else {
          state.emailMsg = 'Failed to get user using the specified email. Please try again later.'
          state.userId = '';
        }
      } else {
        state.emailMsg = 'Failed to send one-time password.';
        state.userId = '';
      }
    });

    builder.addCase(verifyOTPAsync.rejected, (state, action: PayloadAction<any>) => {
      state.pincodeMsg = `Failed: ${action.payload}. Please try again.`;
      state.pinVerified = false;
    });
    builder.addCase(verifyOTPAsync.fulfilled, (state) => {
      state.pincodeMsg = '';
      state.pinVerified = true;
    });


    builder.addCase(createPasswordAsync.pending, (state) => {
      state.passwordFailMsg = '';
      state.passwordSubmitting = true;
    });
    builder.addCase(createPasswordAsync.rejected, (state, action: PayloadAction<any>) => {
      state.passwordFailMsg = `Failed to reset password: ${action.payload}. Please try again later`;
      state.passwordSubmitting = false;
    });
  }
});
