import http from './http';

function register(d: FormData) {
  return http.post('/user/register', d);
}

function verify(uid: string, key: string) {
  let d = new FormData();
  d.set('uid', uid);
  d.set('key', key);
  return http.post('/user/verify', d);
}

export { verify, register };
