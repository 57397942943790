import { useState, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { get_user, is_admin } from '../../auth';
import React from 'react';
import {
  AnalyticsState,
  DisplayAnalyticsRecord,
  slice,
  submitAsync,
} from './analyticsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Excel from 'exceljs';
import { download } from '../../utils';
import Select from 'react-select';
import styled from 'styled-components';
import { MainLayout } from '../../components/MainLayout';
import { PeriodSelect } from '../../components/PeriodSelect';
import _ from 'lodash';
import DateSelect from '../../components/DateSelect';

const ForFieldSelectDiv = styled.div`
  vertical-align: top;
  display: inline-block;
`;

const TableDiv = styled.div`
  overflow: auto;
  max-width: 100%;
`;

const TableBody = styled.tbody`
  white-space: nowrap;
`;

let fields: Record<AnalyticsState['sby'], (keyof DisplayAnalyticsRecord)[]> = {
  month: ['date', 'app_name', 'cnt_user', 'cnt_new_user', 'rate_1m_retention'],
  day: [
    'date',
    'app_name',
    'cnt_user',
    'cnt_new_user',
    'rate_1d_retention',
    'rate_7d_retention',
    'rate_30d_retention',
  ],
};
function exportExcel(tableRef: HTMLTableElement, filename: string) {
  let table = tableRef;
  let trs = [...table.children[1].children];
  let ths = [...table.children[0].children[0].children];
  let columns = ths.map((th) => ({ name: th.textContent || '' }));

  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet('Sheet1');

  // indices of the retention data
  let percentage_idx = [4, 5, 6];
  percentage_idx.forEach((i) => (sheet.getColumn(i + 1).numFmt = '0.00%'));

  let rows: (string | number)[][] = trs.map((tr) =>
    [...tr.children].map((td, ind) => {
      const val = td.textContent || '';
      if (percentage_idx.includes(ind)) {
        return +val.slice(0, -1) / 100.0;
      } else {
        return val;
      }
    })
  );

  sheet.addTable({
    name: 'MyTable',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      //theme: 'TableStyleDark3',
      // showRowStripes: true,
    },
    columns: columns,
    rows: rows,
  });
  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      download(
        buffer,
        filename,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    );
}
export const Analytics: React.FC = () => {
  const [startDateD, setStartDateD] = useState(
    DateTime.local()
      .minus({
        day: 30,
      })
      .toJSDate()
  );
  const [endDateD, setEndDateD] = useState(DateTime.local().toJSDate());
  const [startDateM, setStartDateM] = useState(
    DateTime.local()
      .minus({
        month: 6,
      })
      .toJSDate()
  );
  const [endDateM, setEndDateM] = useState(DateTime.local().toJSDate());
  const [by, setBy] = useState('day');
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);
  const tableRef = useRef<HTMLTableElement | null>(null);
  const state = useSelector<RootState, AnalyticsState>(
    (state: any) => state[slice.name]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    submitBtnRef.current?.click();
  }, []);

  let retent_str = (d: number) =>
    `% of users who were new users ${d} ${state.sby}${
      d > 1 ? 's' : ''
    } ago and returned to the app`;
  return (
    <MainLayout>
      <div className='is-fluid container compact'>
        <div className='section'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(submitAsync(new FormData(e.currentTarget)));
            }}
          >
            <div className='field is-inline-block m-l-sm'>
              <label className='label' data-cy='fromTitle'>
                From
                <span className='has-text-grey-light is-size-7'>
                  {' '}
                  UTC±00:00
                </span>
              </label>
              <div className='control'>
                <DateSelect
                  name='from'
                  selected={by === 'day' ? startDateD : startDateM}
                  onChange={by === 'day' ? setStartDateD : setStartDateM}
                  minDate={new Date(2019, 1, 1)}
                  by={by}
                />
              </div>
            </div>
            <div className='field is-inline-block m-l-sm'>
              <label className='label' data-cy='to'>
                To
              </label>
              <div className='control'>
                <DateSelect
                  name='to'
                  selected={by === 'day' ? endDateD : endDateM}
                  onChange={by === 'day' ? setEndDateD : setEndDateM}
                  minDate={by === 'day' ? startDateD : startDateM}
                  by={by}
                />
              </div>
            </div>
            <div className='field is-inline-block m-l-sm'>
              <label className='label' data-cy='perTitle'>
                Period
              </label>
              <div className='select'>
                <PeriodSelect
                  data-cy='period'
                  name='by'
                  value={by}
                  onChange={(e) => setBy(e.target.value)}
                >
                  <option value='day'>day</option>
                  <option value='month'>month</option>
                </PeriodSelect>
              </div>
            </div>
            <div className='field is-inline-block m-l-sm'>
              <label className='label' data-cy='for'>
                For
              </label>
              <ForFieldSelectDiv className='control'>
                <Select
                  isMulti
                  options={state.apps.map((x) => ({ value: x, label: x }))}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  placeholder='Select apps...'
                  value={state.selectedApps.map((x) => ({ value: x, label: x }))}
                  onChange={(value) => {
                    if (Array.isArray(value)) {
                      dispatch(slice.actions.filter(value.map((x) => x.value)));
                    }
                    else {
                      dispatch(slice.actions.filter([]));
                    }
                  }}
                />
              </ForFieldSelectDiv>
            </div>
            {is_admin() ? (
              <div className='field is-inline-block m-l-sm'>
                <label className='label' data-cy='pub'>
                  Publisher
                </label>
                <p className='control'>
                  <input
                    data-cy='pubID'
                    className='input'
                    type='text'
                    placeholder='Publisher ID/email'
                    name='publisher_id'
                    required
                  />
                </p>
              </div>
            ) : (
              <input
                className='input'
                type='hidden'
                name='publisher_id'
                value={get_user().PublisherId}
              />
            )}
            <div className='field is-inline-block m-l-sm'>
              <button
                ref={submitBtnRef}
                className='button is-primary'
                id='submit'
                type='submit'
              >
                Submit
              </button>
            </div>
            <div className='field is-inline-block m-l-sm'>
              <button
                data-cy='submitBtn'
                className='button is-light'
                onClick={(_) => {
                  if (tableRef.current) {
                    exportExcel(tableRef.current, 'analytics.xlsx');
                  }
                }}
                type='button'
              >
                Export Excel
              </button>
            </div>
          </form>
          <TableDiv>
            <table className='table is-size-7' ref={tableRef}>
              <thead>
                <tr data-cy='analyticsTitle'>
                  <th>Date</th>
                  <th>App</th>
                  <th
                    className='tooltip is-tooltip-bottom'
                    data-tooltip='number of unique users'
                  >
                    Users
                  </th>
                  <th
                    className='tooltip is-tooltip-bottom'
                    data-tooltip='number of unique new users who use the app first time'
                  >
                    New Users
                  </th>
                  {state.sby === 'day' ? (
                    <>
                      <th
                        className='tooltip is-tooltip-bottom is-tooltip-multiline'
                        data-tooltip={retent_str(1)}
                      >
                        Day 1 Retention
                      </th>
                      <th
                        className='tooltip is-tooltip-bottom is-tooltip-multiline'
                        data-tooltip={retent_str(7)}
                      >
                        Day 7 Retention
                      </th>
                      <th
                        className='tooltip is-tooltip-bottom is-tooltip-multiline'
                        data-tooltip={retent_str(30)}
                      >
                        Day 30 Retention
                      </th>
                    </>
                  ) : (
                    <th
                      className='tooltip is-tooltip-bottom is-tooltip-multiline'
                      data-tooltip={retent_str(1)}
                    >
                      1 month Retention
                    </th>
                  )}
                </tr>
              </thead>
              <TableBody>{renderColumns(state)}</TableBody>
              {state.stats &&
              state.stats.length > 0 &&
              state.sby === 'month' ? (
                <tfoot className='total'>
                  <tr>
                    {fields['month'].map((k) => (
                      <td key={k}>
                        {['cnt_user', 'cnt_new_user'].includes(k)
                          ? _.sumBy(state.stats, (x) => +x[k])
                          : ''}
                      </td>
                    ))}
                  </tr>
                </tfoot>
              ) : null}
            </table>
          </TableDiv>
          {state.stats && state.stats.length === 0 ? (
            <div className='notification is-info'>
              Data is being prepared, please check in 10 minutes.
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </MainLayout>
  );
};

function renderColumns(state: AnalyticsState) {
  let fd: (keyof DisplayAnalyticsRecord)[] = fields[state.sby];
  return (
    state.stats &&
    (state.selectedApps.length > 0
      ? state.stats.filter((x) =>
          state.selectedApps.some((f) => f === x.app_name)
        )
      : state.stats
    ).map((x, trInd) => (
      <tr key={trInd}>
        {fd.map((f, tdInd) => (
          <td key={tdInd}>{x[f]}</td>
        ))}
      </tr>
    ))
  );
}
