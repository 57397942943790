import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { login } from '../../auth';
import { push } from 'connected-react-router';

export const initialState = {
  msg: '',
};

export type LoginState = typeof initialState;

export const saveAsync = createAsyncThunk(
  `login/save`,
  async ({ form }: { form: FormData }, { dispatch, rejectWithValue }) => {
    try {
      await login(form);
      dispatch(push('/'));
    } catch (res) {
      return rejectWithValue(res.data);
    }
  }
);

export const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    msg: (state, action: PayloadAction<string>) => {
      const v = action.payload;
      state.msg = v;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAsync.rejected, (state, action: PayloadAction<any>) => {
      const data = action.payload;
      let errMsg = 'Error occurred, please try again later';
      if (data && data.msg && data.msg === 'Invalid username or password') {
        errMsg = data.msg;
      }
      state.msg = errMsg;
    });
  },
});
