import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KaiAdsSSPAPI } from '../../api';
import { push } from 'connected-react-router';
import { RootState } from '../../app/store';

export const initialState = {
  failed: '',
  pass1: '',
  pass2: '',
  pass_match: true,
};

export type RegisterState = typeof initialState;

export const saveAsync = createAsyncThunk(
  'register/save',
  async (
    { form }: { form: FormData },
    { getState, rejectWithValue, dispatch }
  ) => {
    const state = getState() as RootState;

    if (state.register.pass_match) {
      try {
        await KaiAdsSSPAPI.register(form);
        dispatch(push('/registrationSuccess'));
      } catch (res) {
        return rejectWithValue(res.data);
      }
    }
  }
);

export const slice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    pass1: (state, action: PayloadAction<string>) => {
      const v = action.payload;
      state.pass1 = v;
      state.pass_match = v === state.pass2;
    },
    pass2: (state, action: PayloadAction<string>) => {
      const v = action.payload;
      state.pass2 = v;
      state.pass_match = v === state.pass1;
    },
    failMsg: (state, action: PayloadAction<string>) => {
      state.failed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAsync.rejected, (state, action: PayloadAction<any>) => {
      const data = action.payload;
      if (data && data.msg) {
        state.failed = data.msg;
      } else {
        state.failed = 'Error occurred, please try again later';
      }
    });
  },
});
