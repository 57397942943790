import { DateTime } from 'luxon';

export function ctr(clicks: number, impressions: number) {
  return (impressions ? (clicks / impressions) * 100 : 0).toFixed(2);
}

export function tz_offset() {
  let now = DateTime.local();
  let offset = now.offset / 60;
  return (offset >= 0 ? '+' : '-') + offset;
}

export function download(
  data: BufferSource | Blob | string,
  filename: string,
  type: string
) {
  const file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob) {
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    // Others
    const a = document.createElement('a'),
      url = URL.createObjectURL(file);

    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();

    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}
