import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KaiAdsSSPAPI } from '../../api';
import { DateTime } from 'luxon';
import * as r from 'runtypes';

export interface SummaryState {
  selected: number;
  Impressions: number;
  Clicks: number;
  series: SummaryRecord[];
}

export const initialState: SummaryState = {
  selected: 0,
  Impressions: 0,
  Clicks: 0,
  series: [],
};

const SummaryRecord = r.Record({
  Impressions: r.Number,
  Clicks: r.Number,
  Time: r.String,
});
export type SummaryRecord = r.Static<typeof SummaryRecord>;

// export type SummaryState = typeof initialState;

interface SummaryResponse {
  data: SummaryRecord[];
  selected: number;
}

export const selectAsync = createAsyncThunk(
  'summary/select',
  async ({
    selected,
    pubID,
  }: {
    selected: number;
    pubID: string;
  }): Promise<SummaryResponse> => {
    let now = DateTime.local().setZone('utc');
    let from = [
      now.startOf('day').minus({ day: now.weekday - 1 }),
      now.startOf('month'),
    ][selected];
    let by = 'day';

    const res = await KaiAdsSSPAPI.summary_since(from, by, pubID);
    const data = r.Array(SummaryRecord).check(res.data) as SummaryRecord[];
    return { data, selected };
  }
);

export const slice = createSlice({
  name: 'summary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      selectAsync.fulfilled,
      (state, action: PayloadAction<SummaryResponse>) => {
        state.selected = action.payload.selected;
        state.Impressions = 0;
        state.Clicks = 0;

        state.series = [];
        action.payload.data.forEach((x) => {
          state.Impressions += x.Impressions;
          state.Clicks += x.Clicks;

          let j = state.series.length;
          if (j && state.series[j - 1].Time === x.Time) {
            state.series[j - 1].Impressions += x.Impressions;
          } else {
            state.series.push(x);
          }
        });
      }
    );
  },
});
