import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { is_admin } from '../../auth';
import _ from 'lodash';
import { AdminState, loadAsync, slice } from './adminSlice';
import { logoutAsync, RootState } from '../../app/store';
import styled from 'styled-components';
import { MainLayout } from '../../components/MainLayout';

const AdminNavDiv = styled.div`
  overflow: auto;
  max-width: 100%;
`;

const CheckboxDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Admin: React.FC = () => {
  const dispatch = useDispatch();

  if (!is_admin()) {
    dispatch(logoutAsync());
    return <></>;
  }

  return (
    <MainLayout>
      <AdminNavDiv className='is-fluid container'>
        <h1 className='title'>Registered Publishers</h1>
        <Publishers />
      </AdminNavDiv>
    </MainLayout>
  );
};

function Publishers() {
  const state = useSelector<RootState, AdminState>(
    (state: any) => state[slice.name]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAsync());
  }, [dispatch]);

  return (
    <>
      <CheckboxDiv>
        {/*<input*/}
        {/*  type='checkbox'*/}
        {/*  id='nz'*/}
        {/*  onChange={(e) => dispatch(slice.actions.nonzero(e.target.checked))}*/}
        {/*/>*/}
        {/*<label className='checkbox' htmlFor='nz'>*/}
        {/*  non-zero impression*/}
        {/*</label>*/}
        {/*<input*/}
        {/*  className='m-l-sm'*/}
        {/*  type='checkbox'*/}
        {/*  id='st'*/}
        {/*  onChange={(e) => dispatch(slice.actions.sort(e.target.checked))}*/}
        {/*/>*/}
        {/*<label className='checkbox' htmlFor='st'>*/}
        {/*  sort by impression*/}
        {/*</label>*/}
      </CheckboxDiv>
      <table className='table is-size-6'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Publishers ID</th>
            {/*<th>1d</th>*/}
            {/*<th>7d</th>*/}
            {/*<th>30d</th>*/}
          </tr>
        </thead>
        <tbody>
          {_.orderBy(
            state.publishers.filter((x) =>
              state.nonzero ? x.Monthly > 0 : true
            ),
            state.sort ? ['Monthly'] : undefined,
            state.sort ? ['desc'] : undefined
          ).map((x, ind) => (
            <tr key={ind}>
              <td>{x.Name}</td>
              <td>{x.Email}</td>
              <td>{x.PublisherId}</td>
              {/*<td>{x.Daily}</td>*/}
              {/*<td>{x.Weekly}</td>*/}
              {/*<td>{x.Monthly}</td>*/}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
