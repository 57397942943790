import React from 'react';
import { saveAsync, slice } from './changePasswordSlice';
import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from '../../components/MainLayout';

export const ChangePassword: React.FC = () => {
  return (
    <MainLayout>
      <div className='container'>
        <h1 className='title h1' data-cy='title'>
          Change password
        </h1>
        <div className='columns is-centered'>
          <div className='column is-5'>
            <PasswordUpdateForm />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

function PasswordUpdateForm() {
  const state = useSelector((state: any) => state[slice.name]);
  const dispatch = useDispatch();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (state.pass_match) {
          dispatch(saveAsync(new FormData(e.currentTarget)));
        }
      }}
    >
      <div className='field'>
        <label className='label'>Current password</label>
        <div className='control'>
          <input
            className='input'
            type='password'
            name='oldpassword'
            required
            data-cy='curPW'
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>New password</label>
        <div className='control'>
          <input
            onChange={(e) => dispatch(slice.actions.pass1(e.target.value))}
            className='input'
            type='password'
            name='password'
            minLength={8}
            required
            data-cy='newPW'
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>New password again</label>
        <div className='control'>
          <input
            onChange={(e) => dispatch(slice.actions.pass2(e.target.value))}
            className='input'
            type='password'
            name='password2'
            minLength={8}
            required
            data-cy='newPWConfirm'
          />
        </div>
      </div>
      <p className='help is-success'>{state.msg}</p>
      <p className='help is-danger' data-cy='formErr'>
        {!state.pass_match
          ? 'The new passwords do not match'
          : state.failed
          ? state.failMsg === 'Invalid Password'
            ? 'The current password is not correct'
            : 'Error occurred, please try again later'
          : ''}
      </p>
      <div className='field'>
        <p className='control'>
          <button
            type='submit'
            className='button is-block is-primary'
            disabled={state.submitting}
            data-cy='saveBtn'
          >
            Save
          </button>
        </p>
      </div>
    </form>
  );
}
