import axios from 'axios';

let unauthorizedCallback: () => void = () => {};

export function handleUnauthorized(f: () => void) {
  unauthorizedCallback = f;
}

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});
http.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';

http.interceptors.response.use(
  function (response) {
    let d = response.data;
    if (d['success'] === false) {
      // user is logged in but no permission to perform the request
      // if(d['msg'] === 'Unacceptable action') {
      // }

      return Promise.reject(response);
    }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      unauthorizedCallback();
    }
    return Promise.reject(error);
  }
);
export default http;
