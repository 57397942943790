import React, { useEffect } from 'react';

import { verifyAsync, VerifyState, slice } from './verifySlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthLayout } from '../../components/AuthLayout';

const MessageP = styled.p`
  font-size: 1rem;
`;

const LoginLink = styled(Link).attrs((p) => ({ to: '/login' }))`
  color: #6722aa !important;
`;

export const Verify: React.FC = () => {
  const state = useSelector<RootState, VerifyState>(
    (state: any) => state[slice.name]
  );
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    dispatch(verifyAsync(urlParams));
  }, [dispatch, location]);

  return (
    <AuthLayout>
      <h1 className='is-size-3 title'>Email verification</h1>
      {state.msg ? (
        <MessageP className='help is-success'>
          {state.msg}
          <br />
          <LoginLink>Log In</LoginLink>
        </MessageP>
      ) : (
        ''
      )}
      {state.error ? (
        <MessageP className='help is-danger'>{state.error}</MessageP>
      ) : (
        ''
      )}
    </AuthLayout>
  );
};
