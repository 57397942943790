import {
  configureStore,
  ThunkAction,
  Action,
  createAsyncThunk,
  combineReducers,
  PayloadAction,
  getDefaultMiddleware,
  Middleware,
} from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware, push } from 'connected-react-router';

import { History, createBrowserHistory } from 'history';
import { slice as registerSlice } from '../features/register/registerSlice';
import { slice as loginSlice } from '../features/login/loginSlice';
import { slice as changePasswordSlice } from '../features/changePassword/changePasswordSlice';
import { slice as summarySlice } from '../features/summary/summarySlice';
import { slice as adminSlice } from '../features/admin/adminSlice';
import { slice as verifySlice } from '../features/verify/verifySlice';
import { slice as detailsSlice } from '../features/details/detailsSlice';
import { slice as analyticsSlice } from '../features/analytics/analyticsSlice';
import { slice as appReportSlice } from '../features/appReport/appReportSlice';
import { slice as statementSlice } from '../features/statement/statementSlice';
import { slice as changeDomainSlice } from '../features/changeDomain/changeDomainSlice';
import { slice as resetPwdSlice } from '../features/resetPassword/resetPwdSlice';
import { logout } from '../auth';

const createRootReducer = (history: History) => {
  return (state: any, action: PayloadAction<any>) => {
    if (
      action.type === 'global/logout/pending' ||
      action.type === 'global/reset'
    ) {
      return appReducers(history)(undefined, action);
    }
    return appReducers(history)(state, action);
  };
};

const appReducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    [registerSlice.name]: registerSlice.reducer,
    [loginSlice.name]: loginSlice.reducer,
    [changePasswordSlice.name]: changePasswordSlice.reducer,
    [summarySlice.name]: summarySlice.reducer,
    [adminSlice.name]: adminSlice.reducer,
    [verifySlice.name]: verifySlice.reducer,
    [detailsSlice.name]: detailsSlice.reducer,
    [analyticsSlice.name]: analyticsSlice.reducer,
    [appReportSlice.name]: appReportSlice.reducer,
    [statementSlice.name]: statementSlice.reducer,
    [changeDomainSlice.name]: changeDomainSlice.reducer,
    [resetPwdSlice.name]: resetPwdSlice.reducer,
  });

// Log all validation errors
const errorLogger: Middleware = (store) => (next) => (action) => {
  if (action.type && typeof action.type === 'string') {
    if (action.type.match('/rejected$') != null && action.error) {
      console.error(action);
    }
  }
  return next(action);
};

export const history = createBrowserHistory();

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware: getDefaultMiddleware().concat(
    errorLogger,
    routerMiddleware(history)
  ),
});

export const logoutAsync = createAsyncThunk(
  'global/logout',
  async (_, { dispatch }) => {
    await logout();
    dispatch(push('/login'));
  }
);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
