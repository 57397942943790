import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KaiAdsSSPAPI } from '../../api';
import * as r from 'runtypes';

const Publisher = r.Record({
  Name: r.String,
  Email: r.String,
  PublisherId: r.String,
  Daily: r.Number,
  Weekly: r.Number,
  Monthly: r.Number,
});

export type Publisher = r.Static<typeof Publisher>;

export type AdminState = {
  nonzero: boolean;
  sort: boolean;
  publishers: Publisher[];
};

export const initialState: AdminState = {
  nonzero: false,
  sort: false,
  publishers: [],
};

export const loadAsync = createAsyncThunk(
  'admin/load',
  async (_, { dispatch }) => {
    const res = await KaiAdsSSPAPI.publishers();
    return r.Array(Publisher).check(res.data) as Publisher[];
  }
);

export const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    nonzero: (state, action: PayloadAction<boolean>) => {
      state.nonzero = action.payload;
    },
    sort: (state, action: PayloadAction<boolean>) => {
      state.sort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAsync.fulfilled, (state, { payload }) => {
      state.publishers = payload;
    });
  },
});
