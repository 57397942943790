import { handleUnauthorized } from './http';
import { verify, register } from './register';
import { stat, summary_since, publishers, analytics, installs, installs_raw } from './stat';
import { get_payment, request_payment } from './statement';

export const KaiAdsSSPAPI = {
  stat,
  summary_since,
  publishers,
  analytics,
  installs,
  installs_raw,
  verify,
  register,
  handleUnauthorized,
  get_payment,
  request_payment,
};
