import React from 'react';
import { Footer } from './Footer';
import { Nav } from './Nav';

export const MainLayout: React.FC = ({ children }) => {
  return (
    <>
      <Nav />
      {children}
      <Footer />
    </>
  );
};
