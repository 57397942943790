import { createAsyncThunk, createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { KaiAdsSSPAPI } from '../../api';
import * as r from 'runtypes';
import _ from 'lodash';
import { DateTime } from 'luxon';

const InstallBreakdown = r.Record({
  app_name: r.Union(r.String, r.Null),
  country: r.String,
  rank: r.String,
  total: r.String,
});
export type InstallBreakdown = r.Static<typeof InstallBreakdown>;

const InstallTrend = r.Record({
  ad_triggered: r.String,
  app_name: r.Union(r.String, r.Null),
  date: r.String,
  others: r.String,
});
export type InstallTrend = r.Static<typeof InstallTrend>;

const InstallResponse = r.Tuple(
  r.Union(r.Array(InstallBreakdown), r.Null),
  r.Union(r.Array(InstallTrend), r.Null)
);
export type InstallResponse = r.Static<typeof InstallResponse>;

const InstallResponseRaw = r.Array(r.Record({
  ad_triggered: r.String,
  country: r.String,
  app_name: r.String,
  date: r.String,
  others: r.String,
  total: r.String
}));
export type InstallResponseRaw = r.Static<typeof InstallResponseRaw>;

export type AppReportState = {
  pub: string;
  error: string;
  total: number;
  loading: boolean;
  data: InstallResponse;
  trend: InstallTrend[];
  breakdown: InstallBreakdown[];
  apps: string[];
  selectedApps: string[];
  exporting: boolean;
};

const initialState: AppReportState = {
  pub: '',
  error: '',
  total: 0,
  loading: false,
  data: [null, null],
  trend: [],
  breakdown: [],
  apps: [],
  selectedApps: [],
  exporting: false,
};

export const submitAsync = createAsyncThunk(
  'appReport/submit',
  async (d: FormData) => {
    const res = await KaiAdsSSPAPI.installs(d);

    return InstallResponse.check(res.data) as InstallResponse;
  }
);

export const installRawAsync = createAsyncThunk(
  'appReport/install_raw',
  async (d: FormData) : Promise<InstallResponseRaw> => {
    const res = await KaiAdsSSPAPI.installs_raw(d);
    return InstallResponseRaw.check(res.data) as InstallResponseRaw;
  }
);

function updateFilter(state: Draft<AppReportState>) {
  let l = state.selectedApps.length;
  let trend = state.data[1] || [];
  let breakdown = state.data[0] || [];
  if (l <= 1) {
    state.trend = trend.filter(x => x.app_name === (l === 0 ? null : state.selectedApps[0]));
    state.breakdown = breakdown.filter(x => +x.rank <= 5 && x.app_name === (l === 0 ? null : state.selectedApps[0]));
  }
  else {
    state.trend = _.chain(trend.filter(x => _.includes(state.selectedApps, x.app_name)))
      .groupBy('date')
      .map((v, k) => ({
        app_name: '', // ignore the original ranking
        ad_triggered: _.sumBy(v, x => +x.ad_triggered),
        others: _.sumBy(v, x => +x.others),
        date: k
      }))
      .sortBy(x => DateTime.fromFormat(x.date, 'dd MMM yyyy'))
      .map(x => ({
        ...x,
        ad_triggered: '' + x.ad_triggered,
        others: '' + x.others
      }))
      .value();
    state.breakdown = _.chain(breakdown.filter(x => _.includes(state.selectedApps, x.app_name)))
      .groupBy('country')
      .map((v, k) => ({
        rank: '', // ignore the original ranking
        total: _.sumBy(v, x => +x.total),
        app_name: '',
        country: k
      }))
      .orderBy(['total'], ['desc'])
      .take(5)
      .map(x => ({
        ...x,
        total: '' + x.total
      }))
      .value();
  }

  state.total =
    sum(state.trend.map((x) => x.others)) +
    sum(state.trend.map((x) => x.ad_triggered));
}

const sum = (x: (string|number)[]) => x.reduce((a: number, b) => +a + +b, 0);

export const slice = createSlice({
  name: 'appReport',
  initialState,
  reducers: {
    filter: (state, action: PayloadAction<string[]>) => {
      state.selectedApps = action.payload;
      updateFilter(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitAsync.pending, (state) => {
      state.loading = true;
      state.error = '';
    });

    builder.addCase(submitAsync.rejected, (state) => {
      state.loading = false;
      state.error = 'Network error, please try again';
    });

    builder.addCase(
      submitAsync.fulfilled,
      (state, action: PayloadAction<InstallResponse>) => {
        state.loading = false;

        const data = action.payload;
        state.data = data;

        if (data[0] == null && data[1] == null) {
          state.error = 'No data is available, please check again later.';
        }
        state.selectedApps = [];
        let trend = data[1] || [];
        state.apps = _.uniq(trend.filter(x => x.app_name !== null).map(x => x.app_name)) as string[]
        updateFilter(state);
      }
    );

    builder.addCase(
      installRawAsync.fulfilled,
      (state, action: PayloadAction<InstallResponseRaw>) => {
        state.exporting = false;
      }
    );

    builder.addCase(installRawAsync.pending, (state) => {
      state.exporting = true;
    });

    builder.addCase(installRawAsync.rejected, (state) => {
      state.exporting = false;
    });
  },
});
