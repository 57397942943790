import React, { useState } from 'react';
import { createPasswordAsync, slice } from './resetPwdSlice';
import { useDispatch, useSelector } from 'react-redux';

export const NewPassword: React.FC = () => {
  const {
    userId,
    userEmail,
    passwordFailMsg,
    passwordSubmitting
  } = useSelector((state: any) => state[slice.name]);
  const dispatch = useDispatch();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(false);

  const handleInputChange = (event: any) => {
    const { id, value } = event.target;

    switch (id) {
      case 'password1':
        setPassword1(value);
        if (value === password2) {
          setPasswordMatch(true);
        }
        break;
      case 'password2':
        setPassword2(value);
        if (value === password1) {
          setPasswordMatch(true);
        }
        break;
      default:
        break;
    }
  }

  return (
    <form
      className='pin-form'
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        formData.set('id', userId);
        dispatch(createPasswordAsync({ form: formData }));
      }}
    >
      <div className='field'>
        {/* Hidden email input for browsers password manager */}
        <input
          hidden={true}
          type='email'
          name='email'
          defaultValue={userEmail}
          autoComplete='username'
        />
        <label className='label'>New password</label>
        <div className='control'>
          <input
            onChange={handleInputChange}
            id='password1'
            className='input'
            type='password'
            name='password'
            minLength={8}
            required
            autoComplete='new-password'
          />
        </div>
      </div>
      <div className='field'>
        <label className='label'>Confirm password</label>
        <div className='control'>
          <input
            onChange={handleInputChange}
            id='password2'
            className='input'
            type='password'
            minLength={8}
            required
            autoComplete='new-password'
          />
        </div>
      </div>
      <div className='field'>
        <p className='message-reminder'>Password length must be greater than or equal to 8</p>
        <p>{passwordFailMsg}</p>
      </div>
      <div className='field'>
        <p className='control'>
          <button
            type='submit'
            className='button is-block is-primary'
            disabled={!passwordMatch || passwordSubmitting}
          >
            Reset
          </button>
        </p>
      </div>
    </form>
  )
}
