import React, { useState, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { get_user, is_admin } from '../../auth';
import { CustomSuspense } from '../../components/CustomSuspense';
import {
  DetailsState,
  DisplayedReport,
  ExportableReport,
  submitAsync,
} from './detailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { slice } from './detailsSlice';
import Excel from 'exceljs';
import { download } from '../../utils';
import Select from 'react-select';
import styled from 'styled-components';
import { MainLayout } from '../../components/MainLayout';
import { FilterWrapper } from '../../components/FilterWrapper';
import {
  GraphDescriptionText,
  GraphDescriptionWrapper,
} from '../../components/GraphDescription';
import { PeriodSelect } from '../../components/PeriodSelect';
import DateSelect from '../../components/DateSelect';

const fields: (keyof ExportableReport)[] = [
  'Time',
  'AppName',
  'Impressions',
  'Clicks',
  'Ctr',
  'EstimatedRevenue',
  'ECPM',
];

const displayColumns = [
  'Date',
  'App',
  'Impressions',
  'Clicks',
  'CTR.',
  'Estimated Revenue',
  'eCPM',
];

const TableMainDiv = styled.div`
  overflow: auto;
  max-width: 100%;
`;

const FinalizeBlockDiv = styled.div`
  width: 30px;
  height: 12px;
  border: solid black 1px;
`;

const TableBody = styled.tbody`
  white-space: nowrap;
`;

function Table({ data }: { data: DisplayedReport[] }) {
  return (
    <>
      <TableMainDiv>
        <div className='is-pulled-right is-size-7' data-cy='finalized'>
          <FinalizeBlockDiv className='has-background-light is-inline-block'>
            {' '}
          </FinalizeBlockDiv>{' '}
          NOTE: Displayed revenue is not finalized
        </div>
        <table className='table is-size-7'>
          <thead>
            <tr data-cy='reportTitle'>
              {displayColumns.map((c, ind) => (
                <th key={ind}>{c}</th>
              ))}
            </tr>
          </thead>
          <TableBody>
            {data.map((x, trInd) => (
              <tr key={trInd}>
                {fields.map((k, tdInd) => (
                  <td
                    key={tdInd}
                    className={
                      (k === 'EstimatedRevenue' || k === 'ECPM') && !x.Finalized
                        ? 'has-background-light'
                        : ''
                    }
                  >
                    {x[k]}
                  </td>
                ))}
              </tr>
            ))}
          </TableBody>
        </table>
      </TableMainDiv>
    </>
  );
}

function exportExcel(displayData: ExportableReport[], filename: string) {
  const columns = displayColumns.map((f) => ({ name: f }));
  const rows = displayData.map((d) =>
    fields.map((f) => {
      if (f === 'Ctr') {
        return +d[f].slice(0, -1) / 100.0;
      } else {
        return d[f];
      }
    })
  );

  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet('Sheet1');
  let i = fields.indexOf('Ctr');
  sheet.getColumn(i + 1).numFmt = '0.00%';
  sheet.addTable({
    name: 'MyTable',
    ref: 'A1',
    headerRow: true,
    totalsRow: false,
    style: {
      //theme: 'TableStyleDark3',
      // showRowStripes: true,
    },
    columns: columns,
    rows: rows,
  });

  (async () => {
    const buffer = await workbook.xlsx.writeBuffer();
    download(
      buffer,
      filename,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
  })();
}

export const Details: React.FC = () => {
  const [startDateD, setStartDateD] = useState(
    DateTime.local()
      .minus({
        day: 30,
      })
      .toJSDate()
  );
  const [endDateD, setEndDateD] = useState(DateTime.local().toJSDate());
  const [startDateM, setStartDateM] = useState(
    DateTime.local()
      .minus({
        month: 6,
      })
      .toJSDate()
  );
  const [endDateM, setEndDateM] = useState(DateTime.local().toJSDate());
  const [by, setBy] = useState('day');
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);
  const state = useSelector<RootState, DetailsState>(
    (state: any) => state[slice.name]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    submitBtnRef.current?.click();
  }, []);

  return (
    <MainLayout>
      <div className='is-fluid container compact'>
        <div className='section'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(submitAsync(new FormData(e.currentTarget)));
            }}
          >
            <FilterWrapper>
              <div className='field'>
                <label className='label' data-cy='fromTitle'>
                  From
                  <span className='has-text-grey-light is-size-7'>
                    {' '}
                    UTC±00:00
                  </span>
                </label>
                <div className='control'>
                  <DateSelect
                    name='from'
                    selected={by === 'day' ? startDateD : startDateM}
                    onChange={by === 'day' ? setStartDateD : setStartDateM}
                    minDate={new Date(2019, 1, 1)}
                    by={by}
                  />
                </div>
              </div>
              <div className='field m-l-sm'>
                <label className='label' data-cy='to'>
                  To
                </label>
                <div className='control'>
                  <DateSelect
                    name='to'
                    selected={by === 'day' ? endDateD : endDateM}
                    onChange={by === 'day' ? setEndDateD : setEndDateM}
                    minDate={by === 'day' ? startDateD : startDateM}
                    by={by}
                  />
                </div>
              </div>
              <div className='field m-l-sm'>
                <label className='label' data-cy='perTitle'>
                  Period
                </label>
                <div className='control'>
                  <div className='select'>
                    <PeriodSelect
                      data-cy='period'
                      name='by'
                      value={by}
                      onChange={(e) => setBy(e.target.value)}
                    >
                      <option value='day'>day</option>
                      <option value='month'>month</option>
                    </PeriodSelect>
                  </div>
                </div>
              </div>
              <div className='field m-l-sm'>
                <label className='label' data-cy='for'>
                  For
                </label>
                <Select
                  isMulti
                  options={state.apps.map((x) => ({ value: x, label: x }))}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  placeholder='Select apps...'
                  value={state.selectedApps.map((x) => ({ value: x, label: x }))}
                  onChange={(value) => {
                    if (Array.isArray(value)) {
                      dispatch(slice.actions.filter(value.map((x) => x.value)));
                    }
                    else {
                      dispatch(slice.actions.filter([]));
                    }
                  }}
                />
              </div>
              {is_admin() ? (
                <div className='field m-l-sm'>
                  <label className='label'>Publisher</label>
                  <p className='control'>
                    <input
                      className='input'
                      type='text'
                      placeholder='Publisher ID/email'
                      name='publisher_id'
                      required
                    />
                  </p>
                </div>
              ) : (
                <input
                  className='input'
                  type='hidden'
                  name='publisher_id'
                  value={get_user().PublisherId}
                />
              )}
              <div className='field m-l-sm'>
                <button
                  data-cy='submitBtn'
                  ref={submitBtnRef}
                  className='button is-primary'
                  type='submit'
                >
                  Submit
                </button>
              </div>
              <div className='field m-l-sm'>
                <button
                  data-cy='excelBtn'
                  className='button is-light'
                  onClick={(_) => {
                    if (state.displayData) {
                      exportExcel(state.displayData, 'report.xlsx');
                    }
                  }}
                  type='button'
                >
                  Export Excel
                </button>
              </div>
              <GraphDescriptionWrapper className='m-l-sm'>
                <GraphDescriptionText data-cy='revTitle'>
                  Total Estimated Revenue(USD)
                </GraphDescriptionText>
                <p className='title' data-cy='revenue'>
                  ${state.ter}
                </p>
              </GraphDescriptionWrapper>
            </FilterWrapper>
          </form>
          {state.displayData && state.displayData.length === 0 ? (
            <div className='notification is-info' data-cy='noti'>
              No data is available, please check again later.
            </div>
          ) : (
            ''
          )}
          <CustomSuspense loading={state.loading} error={state.error}>
            <Table data={state.displayData || []} />
          </CustomSuspense>
        </div>
      </div>
    </MainLayout>
  );
};
