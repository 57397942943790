import React from 'react';
import { slice } from './resetPwdSlice';
import { useSelector } from 'react-redux';
import { AuthLayout } from '../../components/AuthLayout';
import { EnterEmail } from './EnterEmail';
import { VerifyPin } from './VerifyPin';
import { NewPassword } from './NewPwd';

export const ResetPassword: React.FC = () => {
  const { pinVerified, userId } = useSelector((state: any) => state[slice.name]);

  return (
    <AuthLayout>
      <h1 className='title is-size-3-mobile is-size-2'>
        Reset password
      </h1>
      <div className='field'>
        {pinVerified ?
          <NewPassword /> :
          userId === '' ? <EnterEmail /> : <VerifyPin />
        }
      </div>
    </AuthLayout>
  );
};
