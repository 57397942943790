import http from '../api/http';

let s = localStorage.getItem('user') ? localStorage : sessionStorage;

function clear() {
  localStorage.clear();
  sessionStorage.clear();
}

async function login(d: FormData) {
  let s2 = d.has('remember') ? localStorage : sessionStorage;
  d.delete('remember');

  const res = await http.post('/user/login', d);

  s = s2;
  clear();
  s.setItem('user', JSON.stringify(res.data));
  // router.go('/');
}

function logout() {
  return http.post('/user/logout').finally(() => {
    clear(); /*router.go('/login')*/
  });
}

function get_user() {
  return JSON.parse(s.getItem('user') || '{}');
}

function logged_in() {
  return Object.keys(get_user()).length !== 0;
}

function is_admin() {
  return get_user().Role === 'admin';
}

function change_password(d: FormData) {
  return http.put(`/user/${get_user().Id}`, d);
}

function get_domain() {
  return http.get(`/user/${get_user().Id}/domain`);
}

function change_domain(d: FormData) {
  return http.put(`/user/${get_user().Id}/domain`, d);
}

function get_otp(d: FormData) {
  return http.post("/user/forgetpw", d);
}

function verify_otp(d: FormData) {
  return http.post("/user/checkotp", d);
}

function reset_password(d: FormData) {
  return http.post("/user/resetpw", d);
}

export {
  login,
  logout,
  get_user,
  logged_in,
  is_admin,
  change_password,
  get_domain,
  change_domain,
  get_otp,
  reset_password,
  verify_otp,
};
