import React from 'react';
import styled from 'styled-components';

import logo from '../assets/KaiOS_logo_2x.png';
import banner from '../assets/02-login.png';
import { Footer } from './Footer';

const KaiImg = styled.img`
  width: 220px;
  margin-left: -14px;
`;

const ContentDiv = styled.div`
  margin: auto 0;
`;

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <>
      <section className='hero is-white has-text-centered-mobile'>
        <div className='container is-fluid'>
          <KaiImg src={logo} alt='logo' data-cy='kaiAdsLogo' />
          <div className='columns'>
            <ContentDiv className='column'>{children}</ContentDiv>
            <div className='column is-hidden-mobile'>
              <img src={banner} alt='banner' />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
