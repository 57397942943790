import React from 'react';
import styled from 'styled-components';

const FooterDiv = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
`;

export const Footer: React.FC = () => {
  return (
    <FooterDiv className='hero-foot'>
      <div className='container'>
        <div className='tabs is-centered'>
          <ul>
            <li>
              <a
                href='https://www.kaiostech.com/terms-of-service/'
                target='_blank'
                rel='noopener noreferrer'
                data-cy='terms'
              >
                Terms
              </a>
            </li>
            <li>
              <a
                href='https://www.kaiostech.com/privacy-policy/'
                target='_blank'
                rel='noopener noreferrer'
                data-cy='privacy'
              >
                Privacy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </FooterDiv>
  );
};
