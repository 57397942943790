
import http from './http';

function get_payment(d: FormData) {
  let publisher_id = d.get('publisher_id');
  d.delete('publisher_id');
  return http.get(`/financials/${publisher_id}/payment`);
}

function request_payment(d: FormData) {
  let publisher_id = d.get('publisher_id');
  d.delete('publisher_id');
  return http.post(`/financials/${publisher_id}/payment_request`, d);
}

export {get_payment, request_payment};