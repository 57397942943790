import React, { useEffect } from 'react';
import { Login } from './features/login/Login';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Register } from './features/register/Register';
import { logged_in } from './auth';
import { Summary } from './features/summary/Summary';
// import { Settings } from './features/settings/Settings';
import { ChangePassword } from './features/changePassword/ChangePassword';
import { Admin } from './features/admin/Admin';
import { Verify } from './features/verify/Verify';
import { Statement } from './features/statement/Statement';
import { RegisterSuccess } from './features/register/RegisterSuccess';
import { Details } from './features/details/Details';
import { Analytics } from './features/analytics/Analytics';
import { ChangeDomain } from './features/changeDomain/ChangeDomain';
import { ResetPassword } from './features/resetPassword/ResetPwd';
import { logoutAsync, RootState } from './app/store';
import { useDispatch, useSelector } from 'react-redux';
import { KaiAdsSSPAPI } from './api';
import { AppReport } from './features/appReport/AppReport';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(props) =>
        logged_in() ? <Component {...props} /> : <Redirect to='/login' />
      }
    />
  );
};

function App() {
  const dispatch = useDispatch();
  const pathname = useSelector(
    (state: RootState) => state.router.location.pathname
  );

  // Logout whenever HTTP 401 is received
  useEffect(() => {
    KaiAdsSSPAPI.handleUnauthorized(() => dispatch(logoutAsync()));

    return () => KaiAdsSSPAPI.handleUnauthorized(() => {});
  }, [dispatch]);

  // Reset store state whenever pathname changes
  useEffect(() => {
    dispatch({ type: `global/reset` });
  }, [pathname, dispatch]);

  return (
    <div className='App'>
      <Switch>
        {/*<PrivateRoute exact path='/settings' component={Settings} />*/}
        <PrivateRoute
          exact
          path='/change_password'
          component={ChangePassword}
        />
        <PrivateRoute
          exact
          path='/change_domain'
          component={ChangeDomain}
        />
        <PrivateRoute exact path='/logout' />
        <PrivateRoute exact path='/details' component={Details} />
        <PrivateRoute exact path='/admin' component={Admin} />
        <PrivateRoute exact path='/analytics' component={Analytics} />
        <PrivateRoute exact path='/apps' component={AppReport} />
        <PrivateRoute exact path='/payment' component={Statement} />

        <Route exact path='/login' component={Login} />
        <Route exact path='/register' component={Register} />
        <Route exact path='/verify' component={Verify} />
        <Route exact path='/registrationSuccess' component={RegisterSuccess} />
        <Route exact path='/reset_password' component={ResetPassword} />
        <PrivateRoute path='/' component={Summary} />
      </Switch>
    </div>
  );
}

export default App;
