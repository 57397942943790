import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KaiAdsSSPAPI } from '../../api';
import _ from 'lodash';
import * as r from 'runtypes';

const RawAnalyticsDailyRecord = r.Record({
  date: r.String,
  app_name: r.String,
  cnt_user: r.String,
  cnt_new_user: r.String,
  rate_1d_retention: r.String,
  rate_7d_retention: r.String,
  rate_30d_retention: r.String,
});

const RawAnalyticsMonthlyRecord = r.Record({
  date: r.String,
  app_name: r.String,
  cnt_user: r.String,
  cnt_new_user: r.String,
  rate_1m_retention: r.String,
});

const RawAnalyticsRecord = r.Intersect(
  RawAnalyticsDailyRecord,
  RawAnalyticsMonthlyRecord
);

export type RawAnalyticsRecord = r.Static<typeof RawAnalyticsRecord>;

export type DisplayAnalyticsRecord = {
  date: string;
  app_name: string;
  cnt_user: string;
  cnt_new_user: string;
  rate_1m_retention: string;
  rate_1d_retention: string;
  rate_7d_retention: string;
  rate_30d_retention: string;
};

export type AnalyticsState = {
  sby: 'day' | 'month';
  pub: string;
  stats: DisplayAnalyticsRecord[] | undefined;
  filter: string;
  apps: string[];
  selectedApps: string[];
};

const initialState: AnalyticsState = {
  sby: 'day',
  pub: '',
  stats: undefined,
  filter: '',
  apps: [],
  selectedApps: [],
};

export const submitAsync = createAsyncThunk(
  'analytics/submit',
  async (d: FormData) => {
    const res = await KaiAdsSSPAPI.analytics(d);

    let data: RawAnalyticsRecord[];
    let sby = r.String.check(d.get('by')) as AnalyticsState['sby'];
    if (sby === 'day') {
      data = r
        .Array(RawAnalyticsDailyRecord)
        .check(res.data)
        .map((x) => ({
          ...x,
          rate_1m_retention: '',
        }));
    } else {
      data = r
        .Array(RawAnalyticsMonthlyRecord)
        .check(res.data)
        .map((x) => ({
          ...x,
          rate_1d_retention: '',
          rate_7d_retention: '',
          rate_30d_retention: '',
        }));
    }
    return { data, sby };
  }
);

export const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    filter: (state, action: PayloadAction<string[]>) => {
      state.selectedApps = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitAsync.fulfilled, (state, { payload }) => {
      const F = (x: number) => (+x).toFixed(2);
      const P = (x: number, y: number) => (y ? F((x / y) * 100) + '%' : '');

      state.stats = payload.data.map((x) => {
        // Convert all fields to string
        return {
          date: x.date,
          app_name: x.app_name,
          cnt_user: '' + x.cnt_user,
          cnt_new_user: '' + x.cnt_new_user,
          rate_1d_retention: P(+x.rate_1d_retention || 0, 1),
          rate_7d_retention: P(+x.rate_7d_retention || 0, 1),
          rate_30d_retention: P(+x.rate_30d_retention || 0, 1),
          rate_1m_retention: P(+x.rate_1m_retention || 0, 1),
        };
      });
      state.apps = _.uniq(state.stats.map((x) => x.app_name));
      state.selectedApps = [];
      state.sby = payload.sby;
    });
  },
});
