import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { change_password } from '../../auth';
import * as r from 'runtypes';

export const initialState = {
  pass1: '',
  pass2: '',
  pass_match: true,
  submitting: false,
  msg: '',
  failed: false,
  failMsg: '',
};

export const saveAsync = createAsyncThunk(
  'changePassword/save',
  async (d: FormData, { rejectWithValue }) => {
    try {
      const res = await change_password(d);
      return res.data;
    } catch (res) {
      if (res.data && res.data.msg) {
        return rejectWithValue(r.String.check(res.data.msg));
      }
      return '';
    }
  }
);

export const slice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    pass1: (state, action: PayloadAction<string>) => {
      const v = action.payload;
      state.pass1 = v;
      state.pass_match = v === state.pass2;
    },
    pass2: (state, action: PayloadAction<string>) => {
      const v = action.payload;
      state.pass2 = v;
      state.pass_match = v === state.pass1;
    },
    failMsg: (state, action: PayloadAction<string>) => {
      state.failed = true;
      state.failMsg = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAsync.pending, (state) => {
      state.failed = false;
      state.msg = '';
      state.submitting = true;
    });

    builder.addCase(saveAsync.rejected, (state, action: PayloadAction<any>) => {
      state.failed = true;
      state.failMsg = action.payload;

      state.pass1 = '';
      state.pass2 = '';
      state.submitting = false;
    });
    builder.addCase(saveAsync.fulfilled, (state) => {
      state.msg =
        'Password updated, please login with the new password next time';

      state.pass1 = '';
      state.pass2 = '';
      state.submitting = false;
    });
  },
});
