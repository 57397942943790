import React, { useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { get_user, is_admin } from '../../auth';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit'
import {
  AppReportState,
  InstallBreakdown,
  InstallTrend,
  submitAsync,
  installRawAsync,
  InstallResponseRaw,
  slice,
} from './appReportSlice';
import { RootState } from '../../app/store';
import { Chart, ChartConfiguration, InteractionMode } from 'chart.js';
import Excel from 'exceljs';
import { download } from '../../utils';
// @ts-ignore
import country from 'country-list-js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import Select from 'react-select';
import styled from 'styled-components';
import { MainLayout } from '../../components/MainLayout';
import { FilterWrapper } from '../../components/FilterWrapper';
import {
  GraphDescriptionText,
  GraphDescriptionWrapper,
} from '../../components/GraphDescription';
import { PeriodSelect } from '../../components/PeriodSelect';
import DateSelect from '../../components/DateSelect';
Chart.plugins.unregister(ChartDataLabels);

const GraphCanvas = styled.canvas`
  max-width: min(100%, 1200px);
  margin: 0px auto;
  cursor: crosshair;
`;

function getChartOpts(): ChartConfiguration {
  return {
    plugins: [ChartDataLabels],
    type: 'line',
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
          pointBackgroundColor: 'rgb(175, 129, 210)',
          borderColor: 'rgb(175, 129, 210)',
          fill: false,
          datalabels: { anchor: 'end', align: 'end' },
        },
      ],
    },
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
        },
      },
      responsive: true,
      scales: {
        xAxes: [
          {
            offset: true,
            //   // ticks: {
            //   //   display: false
            //   // }
          },
        ],
      },
      title: { display: true, text: 'App Installations' },
      tooltips: {
        mode: 'nearest' as InteractionMode, //'nearest',
        intersect: false,
      },
      legend: {
        display: false,
      },
    },
  };
}

function getBreakdownChartOpts(): ChartConfiguration {
  return {
    plugins: [ChartDataLabels],
    type: 'horizontalBar',
    data: {
      datasets: [
        {
          backgroundColor: 'rgb(175, 129, 210)',
          borderColor: 'rgb(175, 129, 210)',
          datalabels: { anchor: 'end', align: 'end' },
        },
      ],
    },
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
        },
      },
      responsive: true,
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Top App Installations by Country',
      },
      scales: {
        xAxes: [
          {
            offset: true,
          },
        ],
      }
    },
  };
}

type GraphState = {
  chart1: Chart;
  chart2: Chart;
};
function Charts({
  trend,
  breakdown,
}: {
  trend: InstallTrend[];
  breakdown: InstallBreakdown[];
}) {
  const [graph, setGraph] = useState<GraphState>();
  const canvas1 = useRef<HTMLCanvasElement>(null);
  const canvas2 = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvas1.current || !canvas2.current) {
      return;
    }
    const chart1 = new Chart(canvas1.current, getChartOpts());
    const chart2 = new Chart(canvas2.current, getBreakdownChartOpts());

    setGraph({ chart1, chart2 });

    return () => {
      chart1.destroy();
      chart2.destroy();
      setGraph(undefined);
    };
  }, []);

  useEffect(() => {
    if (!graph) {
      return;
    }
    graph.chart1.data.labels = trend.map((x) => x.date);
    graph.chart1.data.datasets = graph.chart1.data.datasets || [];
    graph.chart1.data.datasets[0].data = trend.map((x) => (+x.others) + (+x.ad_triggered));
    graph.chart1.update();

    graph.chart2.data.labels = breakdown.map((x) => {
      let co = country.findByIso2(x.country.toUpperCase());
      return co ? co.name : x.country;
    });
    graph.chart2.data.datasets = graph.chart2.data.datasets || [];
    graph.chart2.data.datasets[0].data = breakdown.map((x) => +x.total);
    graph.chart2.update();
  }, [graph, trend, breakdown]);

  return (
    <>
      <GraphCanvas ref={canvas1} className='is-centered' />
      <GraphCanvas ref={canvas2} className='is-centered' />
    </>
  );
}

export const AppReport: React.FC = () => {
  const [startDateD, setStartDateD] = useState(
    DateTime.local()
      .minus({
        day: 30,
      })
      .toJSDate()
  );
  const [endDateD, setEndDateD] = useState(DateTime.local().toJSDate());
  const [startDateM, setStartDateM] = useState(
    DateTime.local()
      .minus({
        month: 6,
      })
      .toJSDate()
  );
  const [endDateM, setEndDateM] = useState(DateTime.local().toJSDate());
  const [by, setBy] = useState('day');
  const state = useSelector<RootState, AppReportState>(
    (state: any) => state[slice.name]
  );
  const formRef = useRef<HTMLFormElement | null>(null);
  const dispatch = useDispatch();
  const installRawDispatch: ((...args: any) => Promise<{payload: InstallResponseRaw}>) = useDispatch();
  const exportFunc = async () => {
    if (formRef.current) {
      try {
        const res = await installRawDispatch(installRawAsync(new FormData(formRef.current)));
        const payload = unwrapResult(res);
        let data = payload || [];
        if (state.selectedApps.length) {
          data = data.filter(x => _.includes(state.selectedApps, x.app_name));
        }
        const fields = ['app name', 'country', 'date', 'total installs', 'ad triggered installs', 'other installs'];
        const columns = fields.map((f) => ({ name: f }));
        const rows = data.map((d) =>
          [d.app_name, d.country, d.date, d.total, d.ad_triggered, d.others]
        );

        const workbook = new Excel.Workbook();
        const sheet = workbook.addWorksheet('Sheet1');
        sheet.addTable({
          name: 'MyTable',
          ref: 'A1',
          headerRow: true,
          totalsRow: false,
          style: {
            //theme: 'TableStyleDark3',
            // showRowStripes: true,
          },
          columns: columns,
          rows: rows,
        });
          const buffer = await workbook.xlsx.writeBuffer();
          download(
            buffer,
            'appreport.xlsx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          );
      }
      catch {
      }
    }
  }

  return (
    <MainLayout>
      <div className='is-fluid container compact'>
        <div className='section'>
          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(submitAsync(new FormData(e.currentTarget)));
            }}
          >
            <FilterWrapper>
              <div className='field'>
                <label className='label'>
                  From
                  <span className='has-text-grey-light is-size-7'>
                    {' '}
                    UTC±00:00
                  </span>
                </label>
                <div className='control'>
                  <DateSelect
                    name='from'
                    selected={by === 'day' ? startDateD : startDateM}
                    onChange={by === 'day' ? setStartDateD : setStartDateM}
                    minDate={new Date(2019, 1, 1)}
                    by={by}
                  />
                </div>
              </div>
              <div className='field m-l-sm'>
                <label className='label'>To</label>
                <div className='control'>
                  <DateSelect
                    name='to'
                    selected={by === 'day' ? endDateD : endDateM}
                    onChange={by === 'day' ? setEndDateD : setEndDateM}
                    minDate={by === 'day' ? startDateD : startDateM}
                    by={by}
                  />
                </div>
              </div>
              <div className='field m-l-sm'>
                <label className='label'>Period</label>
                <div className='control'>
                  <div className='select'>
                    <PeriodSelect
                      name='by'
                      value={by}
                      onChange={(e) => setBy(e.target.value)}
                    >
                      <option value='day'>day</option>
                      <option value='month'>month</option>
                    </PeriodSelect>
                  </div>
                </div>
              </div>
              <div className='field m-l-sm'>
                <label className='label' data-cy='for'>
                  For
                </label>
                <Select
                  isMulti
                  options={state.apps.map((x) => ({ value: x, label: x }))}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  placeholder='Select apps...'
                  value={state.selectedApps.map((x) => ({ value: x, label: x }))}
                  onChange={(value) => {
                    if (Array.isArray(value)) {
                      dispatch(slice.actions.filter(value.map((x) => x.value)));
                    }
                    else {
                      dispatch(slice.actions.filter([]));
                    }
                  }}
                />
              </div>
              {is_admin() ? (
                <div className='field m-l-sm'>
                  <label className='label'>Publisher</label>
                  <p className='control'>
                    <input
                      className='input'
                      type='text'
                      placeholder='Publisher ID/email'
                      name='publisher_id'
                      required
                    />
                  </p>
                </div>
              ) : (
                <input
                  className='input'
                  type='hidden'
                  name='publisher_id'
                  value={get_user().PublisherId}
                />
              )}
              <div className='field m-l-sm'>
                <button className='button is-primary' type='submit'>
                  Submit
                </button>
              </div>
              <div className='field m-l-sm'>
                <button
                  data-cy='excelBtn'
                  className='button is-light'
                  disabled={state.exporting||state.loading}
                  onClick={exportFunc}
                  type='button'
                >
                  Export Excel
                </button>
              </div>
              <div></div>
            </FilterWrapper>
          </form>
          {state.error ? (
            <div className='notification is-info'>{state.error}</div>
          ) : (
            ''
          )}
          <div className='columns'>
            <div className='column'>
              <h1 className='subtitle'>App Installation Trend</h1>
              <Charts trend={state.trend} breakdown={state.breakdown} />
            </div>
            <GraphDescriptionWrapper className='is-narrow'>
              <GraphDescriptionText>
                Total App Installation Device Count
              </GraphDescriptionText>
              <p className='title'>{state.total}</p>
            </GraphDescriptionWrapper>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
