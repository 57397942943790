import styled from 'styled-components';

export const GraphDescriptionWrapper = styled.div.attrs<string>(() => ({
  className: 'column has-text-right',
}))`
  white-space: nowrap;
`;

export const GraphDescriptionText = styled.p.attrs<string>(()=> ({
  className: 'heading',
}))`
  font-size: 10px;
`;
