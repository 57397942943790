import React, { useEffect, useRef, useState } from 'react';
import {
  selectAsync,
  slice,
  SummaryRecord,
  SummaryState,
} from './summarySlice';
import { useDispatch, useSelector } from 'react-redux';
import { ctr } from '../../utils';
import { Chart } from 'chart.js';
import { RootState } from '../../app/store';
import { Link } from 'react-router-dom';
import { get_user } from '../../auth';
import styled from 'styled-components';
import { MainLayout } from '../../components/MainLayout';

const GraphCanvas = styled.canvas`
  max-width: 960px;
  margin: 0 auto;
  cursor: crosshair;
`;
const SummaryChart = React.memo(({ series }: { series: SummaryRecord[] }) => {
  let [chart, setChart] = useState<Chart>();
  let canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const chartObj = newChart(canvasRef.current);
    setChart(chartObj);

    return () => {
      chartObj.destroy();
      setChart(undefined);
    };
  }, [canvasRef]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.data.datasets = chart.data.datasets || [];
    chart.data.datasets[0].label = `Daily impressions`;
    chart.data.datasets[0].data = series.map((x) => x.Impressions);
    chart.data.labels = series.map((x) => x.Time);
    chart.update();
  }, [chart, series]);

  return (
    <GraphCanvas
      ref={canvasRef}
      className='is-centered'
      data-cy='chart'
    ></GraphCanvas>
  );
});

function newChart(context: HTMLCanvasElement) {
  return new Chart(context, {
    type: 'line',
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
          pointBackgroundColor: 'rgb(111, 2, 181)',
          borderColor: 'rgb(111, 2, 181)',
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      scales: {
        xAxes: [
          {
            ticks: {
              display: false,
            },
          },
        ],
      },
      title: { text: '', display: true },
      tooltips: {
        mode: 'nearest',
        intersect: false,
      },
      legend: {
        labels: { usePointStyle: true, boxWidth: 10 },
      },
    },
  });
}

export const Summary: React.FC = () => {
  const { PublisherId, Name } = get_user();
  const state = useSelector<RootState, SummaryState>(
    (state: any) => state[slice.name]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectAsync({ selected: 0, pubID: PublisherId }));
  }, [dispatch, PublisherId]);

  return (
    <MainLayout>
      <div className='container is-fluid'>
        <section className='section'>
          <h1 className='title' data-cy='title'>
            Summary for {Name}
          </h1>
          <div className='has-text-grey-light' data-cy='pubID'>
            {'publisher id: ' + PublisherId}
          </div>
          <br />
          <Link className='is-pulled-left' to='/details'>
            Details
          </Link>
          <div className='buttons has-addons is-right'>
            {[
              ['This week', 'tWeek'],
              ['This month', 'tMonth'],
            ].map((x, i) => (
              <span
                key={i}
                className={`button ${
                  state.selected === i ? 'is-selected' : ''
                }`}
                data-cy={x[1]}
                onClick={() =>
                  dispatch(selectAsync({ selected: i, pubID: PublisherId }))
                }
              >
                {x[0]}
              </span>
            ))}
          </div>
          <div className='stats columns'>
            <div className='column has-text-centered'>
              <div data-cy='impressions'>
                <p className='heading'>Impressions</p>
                <p className='title'>{state.Impressions}</p>
              </div>
            </div>
            <div className='column has-text-centered'>
              <div data-cy='clicks'>
                <p className='bd-notification is-primary heading'>Clicks</p>
                <p className='title'>{state.Clicks}</p>
              </div>
            </div>
            <div className='column has-text-centered'>
              <div data-cy='ctr'>
                <p className='bd-notification is-primary heading'>
                  Click through rate
                </p>
                <p className='title'>{ctr(state.Clicks, state.Impressions)}%</p>
              </div>
            </div>
          </div>
          <SummaryChart series={state.series} />
        </section>
      </div>
    </MainLayout>
  );
};
