import React from 'react';
import { Link } from 'react-router-dom';
import { AuthLayout } from '../../components/AuthLayout';
import { saveAsync, slice } from './registerSlice';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const ExternalAnchor = styled.a`
  color: #3273dc !important;
`;

const LoginLink = styled(Link)`
  color: #6722aa !important;
`;

export const Register: React.FC = () => {
  const state = useSelector((state: any) => state[slice.name]);
  const dispatch = useDispatch();

  return (
    <AuthLayout>
      <h1 className='title is-size-3-mobile is-size-2'>Sign Up</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(saveAsync({ form: new FormData(e.currentTarget) }));
        }}
      >
        <div className='field'>
          <div className='control has-icons-left'>
            <input
              onChange={() => dispatch(slice.actions.failMsg(''))}
              name='email'
              className='input'
              type='email'
              placeholder='Your Email'
              autoFocus
              required
              data-cy='formEmail'
            />
            <span className='icon is-left has-text-primary'>
              <i className='fas fa-user'></i>
            </span>
          </div>
        </div>
        <div className='field'>
          <div className='control has-icons-left'>
            <input
              name='name'
              className='input'
              type='text'
              placeholder='Your Name'
              required
              data-cy='formName'
            />
            <span className='icon is-left has-text-primary'>
              <i className='fas fa-caret-right'></i>
            </span>
          </div>
        </div>
        <div className='field'>
          <div className='control has-icons-left'>
            <input
              onChange={(e) => dispatch(slice.actions.pass1(e.target.value))}
              name='password'
              className='input'
              type='password'
              placeholder='Your Password'
              minLength={8}
              value={state.pass1}
              data-cy='formPassword'
            />
            <span className='icon is-left has-text-primary'>
              <i className='fas fa-lock'></i>
            </span>
          </div>
        </div>
        <div className='field'>
          <div className='control has-icons-left'>
            <input
              onChange={(e) => dispatch(slice.actions.pass2(e.target.value))}
              className='input'
              type='password'
              placeholder='Retype Password'
              minLength={8}
              value={state.pass2}
              data-cy='formPasswordConfirm'
            />
            <span className='icon is-left has-text-primary'>
              <i className='fas fa-lock'></i>
            </span>
          </div>
          <p className='help is-danger' data-cy='formErr'>
            {!state.pass_match
              ? 'The passwords do not match'
              : state.failed
              ? state.failed === 'User already exist'
                ? 'The email is already registered'
                : ''
              : ''}
          </p>
        </div>
        <div className='field'>
          <div className='control has-icons-left'>
            <input
              name='domain'
              className='input'
              type='text'
              pattern='^(?:[_a-z0-9](?:[_a-z0-9-]{0,61}[a-z0-9])?\.)+(?:[a-z](?:[a-z0-9-]{0,61}[a-z0-9])?)?$'
              placeholder='Your Domain (e.g. your-domain.com)'
              data-cy='formDomain'
            />
            <span className='icon is-left has-text-primary'>
              <i className='fas fa-caret-right'></i>
            </span>
          </div>
          <p className='help is-danger' data-cy='formErr'>{state.failed}</p>
        </div>
        <div className='field has-text-left'>
          <span data-cy='privacyText'>
            KaiOS will use your personal data to help us creating your account
            and to support any other KaiOS services you might use. Please have a
            look at our{' '}
            <ExternalAnchor
              target='_blank'
              href='https://www.kaiostech.com/privacy-policy/'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </ExternalAnchor>{' '}
            and
            <ExternalAnchor
              target='_blank'
              href='https://www.kaiostech.com/sdk-agreement/'
              rel='noopener noreferrer'
            >
              {' '}
              KaiAds SDK Agreement
            </ExternalAnchor>{' '}
            for more information on how we use your data.
          </span>
          <br />
          <br />
          <div className='is-inline-block'>
            <input id='agree' type='checkbox' required data-cy='agreeCheck' />
            <label className='label is-inline-block' htmlFor='agree'></label>
          </div>
          <span data-cy='agreeStat'>
            I agree to{' '}
            <ExternalAnchor
              target='_blank'
              href='https://www.kaiostech.com/terms-of-service/'
              rel='noopener noreferrer'
            >
              Terms of Services
            </ExternalAnchor>{' '}
            and KaiOS{' '}
            <ExternalAnchor
              target='_blank'
              href='https://www.kaiostech.com/privacy-policy/'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </ExternalAnchor>
            .
          </span>
        </div>
        <button
          type='submit'
          className='button is-block is-primary is-fullwidth'
          data-cy='registerBtn'
        >
          Sign up
        </button>
      </form>
      <p>
        <br />
        <span data-cy='belowFormText'>Have an account? </span>
        <LoginLink to='/login' data-cy='loginPageLink'>
          Log In.
        </LoginLink>{' '}
        &nbsp;
      </p>
    </AuthLayout>
  );
};
