import React, { useCallback, useEffect, useRef } from 'react';
import { PIN_LENGTH, sendEmailAsync, verifyOTPAsync, slice } from './resetPwdSlice';
import { useDispatch, useSelector } from 'react-redux';

function checkIfNumeric(input: string): boolean {
  const pattern = /[0-9]/;
  return pattern.test(input);
}

export const VerifyPin: React.FC = () => {
  const {
    userId,
    userEmail,
    pincode,
    pincodeMsg,
    pinResendable,
    emailMsg,
  } = useSelector((state: any) => state[slice.name]);
  const dispatch = useDispatch();
  const inputRefs: React.RefObject<HTMLInputElement>[] = [];

  function handleInputChange(e: any) {
    const value = e.target.value;
    const itemIndex = e.target.getAttribute('data-index');
    const isNumericInput = checkIfNumeric(value);

    let payload = {
      index: itemIndex,
      value: '',
      msg: '',
    }

    if (value === '' || isNumericInput) {
      payload.value = value;
    } else {
      payload.msg = 'Please enter numeric value';
    }

    dispatch(slice.actions.pincode(payload))
  }

  function handleResend(e: any) {
    startResendTimer();
    const formData = new FormData();
    formData.set('email', userEmail);
    dispatch(sendEmailAsync({ form: formData }));
  }

  // Set an one-minute timer
  // 1. On the first load
  // 2. When user clicks on the Resend button
  const startResendTimer = useCallback(() => {
    dispatch(slice.actions.pinResendable(false));

    const timer = setTimeout(() => {
      dispatch(slice.actions.pinResendable(true));
      clearTimeout(timer);
    }, 60000);
  }, [ dispatch ]);

  const PinItem: React.FC<{index: number}> = ({ children, index }) => {
    const ref = useRef(null);

    useEffect(() => {
      inputRefs.push(ref)
    }, [])

    return (
      <input
        type='text'
        data-index={index}
        ref={ref}
        maxLength={1}
        className='pin-item'
        onChange={handleInputChange}
        value={pincode[index]}
      />
    )
  };

  const PinItems = Array.from({ length: PIN_LENGTH }, (_, index) => (
    <PinItem
      key={index}
      index={index}
    />
  ));

  // Find the 1st non-empty pin slot and focus on it
  const handleFocus = useCallback(() => {
    const currentPosition = pincode.findIndex((element: string) => element === '');
    if (currentPosition === -1) {
      return;
    }

    const element = inputRefs[currentPosition];
    if (element && element.current) {
      element.current.focus()
    }

  }, [pincode, inputRefs]);

  useEffect(() => {
    startResendTimer();
  }, [ startResendTimer ]);

  useEffect(() => {
    handleFocus()
  }, [pincode, handleFocus]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData();
        const pin = pincode.join('');
        formData.set('otpcode', pin);
        formData.set('id', userId);
        dispatch(verifyOTPAsync({ form: formData }));
      }}
    >
      <p className='is-primary'>{emailMsg}</p>
      <div className='pin-box'>
        {PinItems}
      </div>
      <div className='message is-danger'>
        <p>{pincodeMsg}</p>
      </div>
      <div className='button-box'>
        <div className='progress-box'>
          <button
            type='button'
            className={
              'button fixed-label-parent ' +
              (pinResendable ? '': 'progress-animation')
            }
            disabled={!pinResendable}
            onClick={pinResendable ? handleResend : undefined}
          >
            <span className='fixed-label'>Resend</span>
          </button>
        </div>
        <button
          type='submit'
          className='button is-primary'
          disabled={pincode.join('').length < PIN_LENGTH}
        >
          Verify
        </button>
      </div>
    </form>
  )
};

