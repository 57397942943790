import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { requestPaymentAsync } from "../features/statement/statementSlice";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

const Header = styled.div`
  margin-bottom: 1rem;
`;

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
`;

const SliderInput = styled.input`
  width: 100%;
`;

const MinMaxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const RadioButtonOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PaymentInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const PaymentInformationField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
`;

const ContactConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ContactEmail = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const RequestPaymentButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

const YesButton = styled.button`
  padding: 0;
  border-style: none;
  :hover {
    cursor: pointer;
  }
`;

const NoButton = styled.button`
  margin-left: 10px;
  padding: 0;
  border-style: none;
  :hover {
    cursor: pointer;
  }
`;

const Footer = styled.div`
  font-size: 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
  left: 0;
  right 0;
  background-color: grey;
  color: white;
  padding: 1rem;
  border-radius: 0.3rem;
  text-align: center;
`;

interface RequestPaymentModalProps {
  onCancel: (e: any) => void;
  pubID: string;
  outstandingAmount: number;
  contact_email: string;
  payment_method: string;
  full_or_company_name: string;
  bank_name: string;
  bank_address: string;
  bank_acc_name: string;
  swift_code: string;
  payoneer_acc: string;
  paypal_acc: string;
  kaidsp_acc: string;
}

export const RequestPaymentModal: FunctionComponent<RequestPaymentModalProps> = (
  props
) => {
  const minReqAmount = 500;

  const formRef = useRef<HTMLFormElement | null>(null);
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);

  const [reqAmount, setReqAmount] = useState(minReqAmount);
  let sliderRef = useRef(false);

  const [paymentMethod, setPaymentMethod] = useState("");

  const paymentInfo = () => {
    switch (paymentMethod) {
      case "bank_transfer":
        return (
          <>
            <div style={{ color: "#6f02b5" }}>
              <b>Payment Information*</b>:
            </div>
            <PaymentInformationField>
              <div style={{ color: "#505050" }}>Full Name / Company Name*:</div>
              <input
                type="text"
                name="full_name"
                className="input"
                style={{ height: "0.8rem" }}
                defaultValue={props.full_or_company_name}
                required
              ></input>
            </PaymentInformationField>
            <PaymentInformationField>
              <div style={{ color: "#505050" }}>Bank Name*:</div>
              <input
                type="text"
                name="bank_name"
                className="input"
                style={{ height: "0.8rem" }}
                defaultValue={props.bank_name}
                required
              ></input>
            </PaymentInformationField>
            <PaymentInformationField>
              <div style={{ color: "#505050" }}>Bank Address*:</div>
              <input
                type="text"
                name="bank_address"
                className="input"
                style={{ height: "0.8rem" }}
                defaultValue={props.bank_address}
                required
              ></input>
            </PaymentInformationField>
            <PaymentInformationField>
              <div style={{ color: "#505050" }}>Account Name*:</div>
              <input
                type="text"
                name="acc_name"
                className="input"
                style={{ height: "0.8rem" }}
                defaultValue={props.bank_acc_name}
                required
              ></input>
            </PaymentInformationField>
            <PaymentInformationField>
              <div style={{ color: "#505050" }}>SWIFT Code*:</div>
              <input
                type="text"
                name="swift_code"
                className="input"
                style={{ height: "0.8rem" }}
                defaultValue={props.swift_code}
                required
              ></input>
            </PaymentInformationField>
          </>
        );
      case "payoneer":
        return (
          <>
            <div style={{ color: "#6f02b5" }}>
              <b>Payment Information*</b>:
            </div>
            <PaymentInformationField>
              <div style={{ color: "#505050" }}>Payoneer Account (Email Address)*:</div>
              <div>
                <input
                  type="email"
                  name="payoneer_acc"
                  className="input"
                  style={{ height: "0.8rem" }}
                  defaultValue={props.payoneer_acc}
                  required
                ></input>
              </div>
            </PaymentInformationField>
          </>
        );
      case "paypal":
        return (
          <>
            <div style={{ color: "#6f02b5" }}>
              <b>Payment Information*</b>:
            </div>
            <PaymentInformationField>
              <div style={{ color: "#505050" }}>PayPal Account*:</div>
              <div>
                <div>
                  <input
                    type="text"
                    name="paypal_acc"
                    className="input"
                    style={{ height: "0.8rem" }}
                    defaultValue={props.paypal_acc}
                    required
                  ></input>
                </div>
              </div>
            </PaymentInformationField>
          </>
        );
      case "kai_dsp":
        return (
          <>
            <div style={{ color: "#6f02b5" }}>
              <b>Payment Information*</b>:
            </div>
            <PaymentInformationField>
              <div style={{ color: "#505050" }}>E-mail of KaiDSP Account*:</div>
              <div>
                <div>
                  <div>
                    <input
                      type="email"
                      name="kaidsp_acc"
                      className="input"
                      style={{ height: "0.8rem" }}
                      defaultValue={props.kaidsp_acc}
                      required
                    ></input>
                  </div>
                </div>
              </div>
            </PaymentInformationField>
          </>
        );
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.payment_method === "bank_transfer") {
      setPaymentMethod("bank_transfer");
    } else if (props.payment_method === "payoneer") {
      setPaymentMethod("payoneer");
    } else if (props.payment_method === "paypal") {
      setPaymentMethod("paypal");
    } else if (props.payment_method === "kai_dsp") {
      setPaymentMethod("kai_dsp");
    }
  }, [props.payment_method]);

  return (
    <React.Fragment>
      <ReactTooltip id="feeForBankTransfer" place="top" effect="solid">
        Bank transfer fees will depend on receiving banks and countries, <br />
        fixed fee and % fee may apply – Estimate around $50 for a $500 payments.
      </ReactTooltip>
      <ReactTooltip id="feeForPayPal" place="top" effect="solid">
        Paypal payment fees will depend on receiving currency and countries.
      </ReactTooltip>
      <Header>
        Select the amount and payment method you wish to collect from your
        earnings. Our team will receive it and treat it in the best delays. <br/>
        (Payments are processed on 2nd and 4th Friday each month.)
      </Header>
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(requestPaymentAsync(new FormData(e.currentTarget)));
          setTimeout(props.onCancel(e), 500);
        }}
      >
        <input type="hidden" name="publisher_id" value={props.pubID} />
        <Slider>
          <div style={{ color: "#6f02b5" }}>
            <b>Amount Requested*</b>:{" "}
            <span style={{ color: "black" }}>US$ {reqAmount}</span>
          </div>
          <SliderInput
            type="range"
            name="requested_amount"
            min={minReqAmount.toString()}
            max={Math.floor(props.outstandingAmount).toString()}
            value={reqAmount}
            onInput={(e) => {
              setReqAmount(+(e.target as HTMLInputElement).value);
              sliderRef.current = true;
            }}
            onClick={(e) => {
              sliderRef.current = false;
            }}
          />
          <MinMaxContainer>
            <div>Min: US${minReqAmount}</div>
            <div>Max: US${Math.floor(props.outstandingAmount)}</div>
          </MinMaxContainer>
        </Slider>
        <RadioButtonsContainer>
          <div style={{ color: "#6f02b5" }}>
            <b>Payment Method*</b>:
          </div>
          <RadioButtonOption>
            <input
              type="radio"
              name="payment_method"
              value="bank_transfer"
              onChange={() => setPaymentMethod("bank_transfer")}
              defaultChecked={props.payment_method === "bank_transfer"}
              required
            />
            <span style={{ marginLeft: "0.5rem", color: "#505050" }}>
              Bank Transfer (Fee will apply
            </span>
            <span
              style={{ marginLeft: "0.5rem", color: "#1E90FF" }}
              data-tip
              data-for="feeForBankTransfer"
            >
              <img
                src="./information.png"
                alt="tooltip-icon"
                style={{ height: "1rem", margin: "0" }}
              />
            </span>
            <span style={{ marginLeft: "0.5rem", color: "#505050" }}>)</span>
          </RadioButtonOption>
          <RadioButtonOption>
            <input
              type="radio"
              name="payment_method"
              value="payoneer"
              onChange={() => setPaymentMethod("payoneer")}
              defaultChecked={props.payment_method === "payoneer"}
              required
            />
            <span style={{ marginLeft: "0.5rem", color: "#505050" }}>
              Payoneer (0% fee)
            </span>
          </RadioButtonOption>
          <RadioButtonOption>
            <input
              type="radio"
              name="payment_method"
              value="paypal"
              onChange={() => setPaymentMethod("paypal")}
              defaultChecked={props.payment_method === "paypal"}
              required
            />
            <span style={{ marginLeft: "0.5rem", color: "#505050" }}>
              PayPal (4.4% of total + Fixed Fee
            </span>
            <span
              style={{ marginLeft: "0.5rem", color: "#1E90FF" }}
              data-tip
              data-for="feeForPayPal"
            >
              <img
                src="./information.png"
                alt="tooltip-icon"
                style={{ height: "1rem", margin: "0" }}
              />
            </span>
            <span style={{ marginLeft: "0.5rem", color: "#505050" }}>)</span>
          </RadioButtonOption>
          <RadioButtonOption>
            <input
              type="radio"
              name="payment_method"
              value="kai_dsp"
              onChange={() => setPaymentMethod("kai_dsp")}
              defaultChecked={props.payment_method === "kai_dsp"}
              required
            />
            <span style={{ marginLeft: "0.5rem", color: "#505050" }}>
              Transfer to KaiDSP as advertising budget (0% fee)
            </span>
          </RadioButtonOption>
        </RadioButtonsContainer>
        <PaymentInformationContainer>
          {paymentInfo()}
        </PaymentInformationContainer>
        <ContactConfirmationContainer>
          <div style={{ color: "#6f02b5" }}>
            <b>Confirm Contact Information*</b>:
          </div>
          <ContactEmail>
            <div
              style={{
                color: "#505050",
              }}
            >
              E-mail:
            </div>
            <input
              type="email"
              name="contact_email"
              className="input"
              style={{ height: "0.8rem" }}
              defaultValue={props.contact_email}
              required
            ></input>
          </ContactEmail>
        </ContactConfirmationContainer>
        <div style={{ fontSize: "0.8rem", marginTop: "2rem" }}>
          * This field is mandatory
        </div>

        <RequestPaymentButtons>
          <YesButton ref={submitBtnRef} type="submit">
            <div
              className="tag button"
              style={{
                color: "white",
                backgroundColor: "#1E90FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                width: "fit-content",
                margin: 0,
              }}
            >
              <span>Confirm Payment Request</span>
            </div>
          </YesButton>
          <NoButton onClick={props.onCancel}>
            <div
              className="tag button"
              style={{
                color: "white",
                backgroundColor: "#DC143C",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                width: "fit-content",
                margin: 0,
              }}
            >
              <span>Cancel</span>
            </div>
          </NoButton>
        </RequestPaymentButtons>
      </form>
      <Footer>
        If you have questions or problems regarding your payment, <br /> please
        reach us at &nbsp;
        <Link
          to="#"
          style={{ color: "white" }}
          onClick={() =>
            (window.location.href = "mailto:developersupport@kaiostech.com")
          }
        >
          <b>developersupport@kaiostech.com</b>
        </Link>
        &nbsp;.
      </Footer>
    </React.Fragment>
  );
};
