import { get_user, is_admin } from '../auth';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/KaiOS_logo_2x.png';
import { logoutAsync } from '../app/store';
import { useDispatch } from 'react-redux';

export const Nav: React.FC = () => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const dispatch = useDispatch();
  function toggleDropdown() {
    setDropdownActive(!dropdownActive);
  }

  return (
    <nav
      className='navbar is-transparent'
      role='navigation'
      aria-label='main navigation'
    >
      <div className='navbar-brand'>
        <span className='navbar-item' data-cy='kaiAdsLogo'>
          <img src={logo} alt='KaiOS' />
        </span>
        <span
          onClick={toggleDropdown}
          role='button'
          className='navbar-burger'
          aria-label='menu'
          aria-expanded='false'
        >
          <span aria-hidden='true' />
          <span aria-hidden='true' />
          <span aria-hidden='true' />
        </span>
      </div>
      <div
        id='navmenu'
        className={'navbar-menu ' + (dropdownActive ? 'is-active' : '')}
      >
        <div className='navbar-start'>
          <Link to='/' className='navbar-item' data-cy='overview'>
            Overview
          </Link>
          {is_admin() ? (
            <Link to='/admin' className='navbar-item'>
              Admin
            </Link>
          ) : (
            ''
          )}
          {
            /*is_admin()*/ false ? (
              <Link to='/relationships' className='navbar-item'>
                Relationships
              </Link>
            ) : (
              ''
            )
          }
          <Link to='/details' className='navbar-item' data-cy='adRep'>
            Ad Reports
          </Link>
          <Link to='/apps' className='navbar-item' data-cy='appRep'>
            App Reports
          </Link>
          <Link to='/analytics' className='navbar-item' data-cy='analytics'>
            Analytics
          </Link>
          <Link to='/payment' className='navbar-item' data-cy='payment'>
            Payment
          </Link>

          {/*<Link to='/settings' className='navbar-item' data-cy='settings'>*/}
          {/*  Settings*/}
          {/*</Link>*/}
          <a
            href='https://kaiads.com/publishers/sdk.html'
            target='_blank'
            className='navbar-item'
            rel='noopener noreferrer'
            data-cy='getSDK'
          >
            Get the SDK
          </a>
        </div>
        <div className='navbar-end'>
          <div className='navbar-item has-dropdown is-hoverable'>
            <span className='navbar-link' data-cy='navMenu'>
              Account
            </span>
            <div className='navbar-dropdown is-right'>
              <div className='navbar-item'>{get_user().Email}</div>
              <Link to='/change_password' className='navbar-item'>
                Change password
              </Link>
              <Link to='/change_domain' className='navbar-item'>
                Change account info
              </Link>
              <span
                onClick={() => dispatch(logoutAsync())}
                className='navbar-item navbar-link is-arrowless'
              >
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
